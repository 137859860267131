import { getHoyEndpoint, serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import getAccessToken from '@Infowijs-eng/component-library/modules/authentication/selectors/getAccessToken'
import {
  all,
  spawn,
} from 'redux-saga/effects'
import { onAuthentication } from '@Infowijs-eng/component-library/modules/authentication'
import { createLocalSagas as createLocalMessageSagas } from '@Infowijs-eng/hoy_shared/modules/messages/sagas'
import getCustomerProductName from '../selectors/customerProduct/getCustomerProductName'
import onFetchUser from './user/onFetchUser'
import onFetchNews from './news/onFetchNews'

const {
  onArchiveConversation,
  onFetchConversations,
  onFetchConversationMeta,
  onFetchConversationMessages,
  onSendMessage,
  onUnarchiveConversation,
} = createLocalMessageSagas({
  clientIdentifier: 'nl.infowijs.hoy.web',
  getAccessToken,
  getHoyEndpoint: (state) => getHoyEndpoint(getCustomerProductName(state)),
  getHoyAdditionalHeaders: () => ({
    'X-Infowijs-Client': 'nl.infowijs.hoy.web',
  }),
  getMessageServiceEndpoint: (_) => serviceEndpoints.messages,
})

const rootSaga = function* rootSaga() {
  yield all([
    spawn(onAuthentication),
    spawn(onFetchUser),
    spawn(onFetchNews),
    spawn(onArchiveConversation),
    spawn(onFetchConversations),
    spawn(onFetchConversationMeta),
    spawn(onFetchConversationMessages),
    spawn(onSendMessage),
    spawn(onUnarchiveConversation),
  ])
}

export default rootSaga
