import React from 'react'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import welcomeGraphic from '../../images/groupchat-placeholder.svg'
import BackgroundBubbles from '../../components/BackgroundBubbles'
import Placeholder from '../../components/Placeholder'

function NoMessageBccScreen() {
  const { i18n } = useLingui()
  return (
    <BackgroundBubbles>
      <div className="h-full flex items-center justify-center p-16">
        <Placeholder
          image={welcomeGraphic}
          title={i18n._('messageGroup.bcc.details.empty.title')}
        >
          <Trans id="messageGroup.bcc.details.empty.description">
            In the left-hand sidebar you can select the main message or individual conversations.
          </Trans>
        </Placeholder>
      </div>
    </BackgroundBubbles>
  )
}

export default NoMessageBccScreen
