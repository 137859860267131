import React from 'react'
import PropTypes from 'prop-types'
import { Paperclip, QuestionMarkCircle } from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'
import { MessageType } from '@Infowijs-eng/hoy_shared/modules/messages/types'
import getFileDescription from '../../modules/getFileDescription'
import MessageParser from './MessageParser'

function MessageBubble({
  type,
  content,
  sentByMe,
  showTail,
}) {
  const bubbleStyle = cn(
    'text-sm relative max-w-lg rounded-xl mt-2',
    !content && 'animate-pulse',
    sentByMe
      ? ['self-end text-white', type === 1 && 'bg-theme-500', type === 1 && showTail && 'rounded-tr-none']
      : ['self-start text-black', type === 1 && 'bg-white', type === 1 && showTail && 'rounded-tl-none'],
    (type === 2 || type === 3) && 'overflow-hidden bg-theme-700 text-white',
    'before:content-[""] before:w-[0px] before:h-[0px] before:absolute before:t-0',
    showTail && !sentByMe && `
      before:border-solid before:border-[0.5em] before:left-[-0.5em]
      before:border-l-[transparent] before:border-r-[transparent] before:border-t-white before:border-b-[transparent]
    `,
    showTail && sentByMe && `
      before:border-solid before:border-[0.5em] before:right-[-0.5em]
      before:border-l-[transparent] before:border-r-[transparent]
      before:border-t-theme-500 before:border-b-[transparent]
    `,
  )

  let fileExtention
  if (typeof content !== 'object') {
    fileExtention = 'error'
  }
  if (content && content.url) {
    fileExtention = content.url.split('.')[content.url.split('.').length - 1]
  }
  return (
    <div className={bubbleStyle}>
      {!content && <div className="w-24 h-10" />}
      {content && type === MessageType.Text && (
        <div
          className={cn(
            'p-3 text-base leading-5 whitespace-pre-wrap',
            sentByMe && 'selection:bg-theme-700',
          )}
        >
          <MessageParser text={content} />
        </div>
      )}
      {content && type === MessageType.File && (
        <a className="flex flex-col" href={content.url} target="_blank" rel="noreferrer" download>
          {fileExtention
            ? <Paperclip fontSize="3rem" className="m-6 self-center opacity-60" />
            : <QuestionMarkCircle fontSize="3rem" className="m-6 self-center opacity-60" />}
          <div className={cn('px-4 py-2 flex font-bold', sentByMe ? 'bg-theme-500 text-white' : 'bg-white text-black')}>
            {getFileDescription(
              fileExtention,
              content.name,
            )}
          </div>
        </a>
      )}
      {content && type === MessageType.Image && (
        <a href={content} target="_blank" rel="noreferrer">
          <img src={content} alt={content} className="w-full max-w-xs" />
        </a>
      )}
    </div>
  )
}

MessageBubble.propTypes = {
  type: PropTypes.number.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ]),
  sentByMe: PropTypes.bool,
  showTail: PropTypes.bool,
}

MessageBubble.defaultProps = {
  content: null,
  sentByMe: false,
  showTail: false,
}

export default MessageBubble
