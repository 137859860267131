import React, {
  useEffect,
  useState,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import PropTypes from 'prop-types'
import {
  ThreeLines,
  XMark,
} from '@Infowijs-eng/component-library/icons'
import {
  Button,
} from '@Infowijs-eng/component-library/components'
import {
  cn,
  isDevelopment,
} from '@Infowijs-eng/component-library/modules'
import CustomerProductAvatar from './CustomerProductAvatar'
import getUser from '../selectors/user/getUser'
import getCustomerProduct from '../selectors/customerProduct/getCustomerProduct'
import isUserLoading from '../selectors/user/isUserLoading'
import fetchUser from '../actions/user/fetchUser'
import useFrontChat from '../hooks/useFrontChat'
import DesktopAccountMenu from './menu/DesktopAccountMenu.tsx'
import MobileAccountMenu from './menu/MobileAccountMenu.tsx'

function Navigation({
  children,
}) {
  const user = useSelector(getUser)
  const customerProduct = useSelector(getCustomerProduct)
  const userLoading = useSelector(isUserLoading)
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false)
  const dispatch = useDispatch()

  if (!isDevelopment) {
    useFrontChat()
  }

  useEffect(() => {
    if (!user.avatar && !userLoading) {
      dispatch(fetchUser())
    }
  }, [user])

  return (
    <div className={cn(
      'fixed top-0 md:h-fit w-screen bg-white shadow z-20 transition-all',
      isMobileNavigationOpen ? 'h-screen' : 'h-fit',
    )}
    >
      <div className="flex items-center h-16 px-4 md:px-6 justify-between">
        {/* Desktop */}
        <div className="hidden md:flex space-x-8">
          <CustomerProductAvatar avatar={customerProduct.avatar} title={customerProduct.title} />
          <div className="hidden md:flex items-center md:space-x-6">
            {children}
          </div>
        </div>

        <div className="theme--hoy hidden md:flex space-x-6">
          <DesktopAccountMenu
            alignRight
          />
        </div>

        {/* Mobile */}
        <div className="flex md:hidden justify-end">
          {!isMobileNavigationOpen && (
            <CustomerProductAvatar avatar={customerProduct.avatar} title={customerProduct.title} />
          )}
          {isMobileNavigationOpen && (
            <div className="flex md:hidden justify-end">
              <CustomerProductAvatar avatar={customerProduct.avatar} title={customerProduct.title} />
              <div className="hidden md:flex">
                {children}
              </div>
            </div>
          )}
        </div>

        <Button
          neutral
          leadingAddon={isMobileNavigationOpen ? <XMark /> : <ThreeLines />}
          onClick={() => setIsMobileNavigationOpen(!isMobileNavigationOpen)}
          className="block md:hidden"
        />
      </div>

      {/* -- Mobile -- */}
      {isMobileNavigationOpen && (
        <div className="theme--hoy flex flex-col h-[calc(100%-64px)] md:hidden md:h-fit overflow-y-scroll">
          {children}
          <MobileAccountMenu />
        </div>
      )}
    </div>
  )
}

Navigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
  ]),
}

Navigation.defaultProps = {
  children: null,
}

export default Navigation
