import React from 'react'

import PropTypes from 'prop-types'

function Sidebar({ children }) {
  return (
    // eslint-disable-next-line max-len
    <div className="z-10 h-full md:min-w-[400px] max-w-[600px] w-full overflow-y-auto md:w-5/12 xl:w-1/3 2xl:w-3/12 bg-white shadow">
      {children}
    </div>
  )
}

Sidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
  ]),
}

Sidebar.defaultProps = {
  children: null,
}

export default Sidebar
