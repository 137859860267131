import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@Infowijs-eng/component-library/components'

function FileInputButton({ onSelectFile, className, ...props }) {
  return (
    <>
      <label
        htmlFor="fileInput"
        className="cursor-pointer"
      >
        <Button
        /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...props}
          className={[className, 'pointer-events-none']}
        />
      </label>
      <input
        id="fileInput"
        type="file"
        className="hidden"
        onChange={(e) => {
          onSelectFile(e.target.files[0])
          e.target.value = null
        }}
      />
    </>
  )
}

FileInputButton.propTypes = {
  onSelectFile: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

FileInputButton.defaultProps = {
  className: null,
}

export default FileInputButton
