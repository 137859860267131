import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'

import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  Heading,
  Skeleton,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowDownArchive,
  ArrowLeft,
  ArrowUpArchive,
  Ellipsis,
  XMarkCircle,
} from '@Infowijs-eng/component-library/icons'
import {
  Plural,
  Trans,
} from '@lingui/macro'
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  cn,
} from '@Infowijs-eng/component-library/modules'
import { i18n } from '@lingui/core'
import {
  archiveConversation,
  fetchConversations,
  unarchiveConversation,
} from '@Infowijs-eng/hoy_shared/modules/messages/actions'
import {
  getConversation,
  makeGetRecipients,
  makeGetVirtualConversation,
} from '@Infowijs-eng/hoy_shared/modules/messages/selectors'
import { ConversationType } from '@Infowijs-eng/hoy_shared/modules/messages/types'
import isInteger from '@Infowijs-eng/hoy_shared/modules/isInteger'
import getFullName from '@Infowijs-eng/hoy_shared/modules/getFullName'
import getUserId from '../../sagas/user/getUserId.ts'
import getMultiRecipientAvatarBgColor from '../../modules/getMultiRecipientAvatarBgColor'
import isMobile from '../../modules/isMobile'

function MessageDetailBar({ inboxId }) {
  const dispatch = useDispatch()
  const getVirtualConversation = useMemo(() => makeGetVirtualConversation(), [])

  const { conversationId, recipient } = useParams()
  const inArchive = !!useMatch('/messages/archive/*')

  const { search } = useLocation()
  const [searchParams] = useSearchParams()
  const pageParam = searchParams.get('page')
  const navigate = useNavigate()

  const getRecipients = makeGetRecipients(getUserId)
  const userId = useSelector(getUserId)
  const mainconversation = useSelector((state) => getConversation(state, {
    inboxId,
    conversationId,
  }))
  const virtualConversation = useSelector((state) => getVirtualConversation(state, {
    inboxId,
    conversationId,
    recipient,
  }))

  const conversation = isInteger(recipient) ? virtualConversation : mainconversation

  const recipients = useSelector((state) => getRecipients(state, {
    inboxId,
    conversationId,
    recipient,
  }))

  useEffect(() => {
    const conversationArchived = conversation?.isArchived
    if (conversationArchived === null || conversationArchived === undefined) {
      return
    }
    if (conversationArchived !== inArchive) {
      dispatch(fetchConversations({ inboxId, fetchArchive: conversationArchived, page: pageParam }))
      if (conversationArchived) {
        navigate('/messages/archive')
      } else {
        navigate('/messages')
      }
    }
  }, [inArchive, conversation?.isArchived])

  let archiveButtonText
  let archiveConfirmText
  let archiveButtonIcon

  if (
    conversation
      && (
        conversation.type === ConversationType.Conversation
          || conversation.type === ConversationType.Announcement
      )
  ) {
    archiveButtonText = conversation.type === ConversationType.Announcement
      ? <Trans id="MessagesDetailScreen.archive.announcement">Archive announcement</Trans>
      : <Trans id="MessagesDetailScreen.archive.conversation">Archive conversation</Trans>
    archiveConfirmText = conversation.type === ConversationType.Announcement
      ? i18n.t('MessagesDetailScreen.archive.confirm.announcement')
      : i18n.t('MessagesDetailScreen.archive.confirm.conversation')

    archiveButtonIcon = <ArrowDownArchive />
  }

  if (conversation && conversation.type === ConversationType.Multicast) {
    if (conversation.createdByMe) {
      archiveButtonText = <Trans id="MessagesDetailScreen.close">Close group conversation</Trans>
      archiveConfirmText = i18n.t('MessagesDetailScreen.close.confirm')
    } else {
      archiveButtonText = <Trans id="MessagesDetailScreen.leave">Leave group</Trans>
      archiveConfirmText = i18n.t('MessagesDetailScreen.leave.confirm')
    }
    archiveButtonIcon = <XMarkCircle />
  }

  const desktopArchiveAddon = conversation
    && !isInteger(recipient)
    && [
      inArchive && (conversation.type !== ConversationType.Multicast) && (
        <Button
          key="desktop-actions"
          small
          leadingAddon={<ArrowUpArchive />}
          onClick={() => dispatch(unarchiveConversation({ inboxId: userId, conversationId }))}
        >
          <Trans id="MessagesDetailScreen.unarchive">Unarchive</Trans>
        </Button>
      ),
      !inArchive && (
        <Button
          key="desktop-actions"
          className="theme--danger"
          small
          leadingAddon={archiveButtonIcon}
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals,no-alert
            if (!confirm(archiveConfirmText)) {
              return
            }
            dispatch(archiveConversation({ inboxId: userId, conversationId }))
          }}
        >
          {archiveButtonText}
        </Button>
      ),
    ]
  const mobileArchiveAddon = conversation
    && (!isInteger(recipient))
    && (
      <Dropdown
        key="mobile-actions"
        alignRight
        icon={<Ellipsis />}
        buttonProps={{
          small: true,
        }}
      >
        {inArchive && (conversation.type !== ConversationType.Multicast) && (
          <DropdownItem
            leadingAddon={<ArrowUpArchive />}
            onClick={() => dispatch(unarchiveConversation({ inboxId: userId, conversationId }))}
          >
            <Trans id="MessagesDetailScreen.unarchive">Unarchive</Trans>
          </DropdownItem>
        )}
        {!inArchive && (
          <DropdownItem
            leadingAddon={<ArrowDownArchive />}
            onClick={() => dispatch(archiveConversation({ inboxId: userId, conversationId }))}
          >
            {archiveButtonText}
          </DropdownItem>
        )}
      </Dropdown>
    )

  let title = null
  let subtitle = null
  let avatarId = null
  let avatarType = null
  let recipientCount = null

  if (conversation?.avatars?.length) {
    const { id, type: aType } = conversation.avatars[0]
    avatarId = id
    avatarType = aType
  }

  if (recipients?.length) {
    title = conversation?.title

    recipientCount = (conversation?.type === ConversationType.Multicast)
      ? recipients.length + 1
      : recipients.length

    subtitle = (
      <Plural
        id="messages.details.participants"
        value={`${recipientCount}`}
        one="# Participant"
        other="# Participants"
      />
    )

    if (recipients.length === 1 && conversation?.type !== ConversationType.Multicast) {
      title = recipients[0].title ?? getFullName(recipients[0])
      subtitle = recipients[0].subtitle
      avatarId = recipients[0].avatar?.id
      avatarType = recipients[0].avatar?.type
      recipientCount = null
    }
  }

  return (
    <div className="sticky top-0 z-10">
      <div className="h-16 px-6 lg:(px-8) flex justify-start items-center bg-white">
        <WithAddon
          fullWidth
          className="justify-start"
          leadingAddon={isMobile() && (
            <Button
              key="back-button"
              neutral
              to={{
                pathname: recipient ? `/messages/${inArchive ? 'archive/' : ''}bcc/${conversationId}` : '/messages',
                search,
              }}
              leadingAddon={<ArrowLeft />}
            />
          )}
          trailingAddon={[
            isMobile() && mobileArchiveAddon,
            !isMobile() && desktopArchiveAddon,
          ]}
        >
          <Heading
            fontSize={Heading.size.H6}
            className={cn('leading-relaxed', !conversation && 'w-36')}
          >
            {conversation && conversation.title}
          </Heading>
        </WithAddon>
      </div>
      <div
        className="w-full bg-theme-500 min-h-[2.5rem]"
      >
        <div className="h-16 px-6 lg:(px-8) flex items-center">
          <Avatar
            id={avatarId}
            type={avatarType}
            count={recipientCount}
            className={recipientCount ? getMultiRecipientAvatarBgColor(recipientCount) : 'from-white bg-gray-50'}
          />
          <div className="ml-4 flex flex-col justify-center text-white space-y-1">
            {title && <span className="text-base font-black line-clamp-1">{title}</span>}
            {!title && <Skeleton className="bg-white w-24" />}
            {subtitle && <span className="text-sm line-clamp-1">{subtitle}</span>}
            {!subtitle && !title && <Skeleton sm className="bg-white w-80" />}
          </div>
        </div>
      </div>
    </div>
  )
}

MessageDetailBar.propTypes = {
  inboxId: PropTypes.string.isRequired,
}

export default MessageDetailBar
