import React, {
  useEffect,
  useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchConversations } from '@Infowijs-eng/hoy_shared/modules/messages/actions'
import {
  getArchiveConversations,
  getArchiveMeta,
} from '@Infowijs-eng/hoy_shared/modules/messages/selectors'
import {
  getFetchArchiveRequestStatus,
} from '@Infowijs-eng/hoy_shared/modules/messages/selectors/status'
import { useConversationsAutoRefresh } from '@Infowijs-eng/hoy_shared/modules/messages/hooks'
import ConversationList from '../../components/messages/ConversationList'
import usePageParam from '../../hooks/usePageParam'
import ConversationHeader from '../../components/messages/ConversationHeader'
import useWindowInFocus from '../../hooks/useWindowInFocus.ts'
import getUserId from '../../sagas/user/getUserId.ts'

function MessagesArchiveScreen() {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const pageParam = usePageParam()
  const userId = useSelector(getUserId)
  const messagesArchive = useSelector((state) => getArchiveConversations(state, { inboxId: userId }))
  const messagesArchiveMeta = useSelector((state) => getArchiveMeta(state, { inboxId: userId }))
  const fetchArchiveRequestStatus = useSelector((state) => getFetchArchiveRequestStatus(state, { inboxId: userId }))

  const inFocus = useWindowInFocus()
  useConversationsAutoRefresh(userId, true, inFocus)

  useEffect(() => {
    dispatch(fetchConversations({
      inboxId: userId,
      offset: (Math.max(0, pageParam - 1) * 50),
      fetchArchive: true,
    }))
  }, [pageParam])

  return (
    <div ref={ref}>
      <ConversationHeader loading={fetchArchiveRequestStatus.isLoading} />
      <ConversationList
        inboxId={userId}
        conversations={messagesArchive}
        meta={messagesArchiveMeta}
        loading={fetchArchiveRequestStatus.isLoading}
        errorMessages={fetchArchiveRequestStatus.errors}
      />
    </div>
  )
}

MessagesArchiveScreen.propTypes = {
}

MessagesArchiveScreen.defaultProps = {
}

export default MessagesArchiveScreen
