import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PageContent from '../components/PageContent'
import Sidebar from '../components/Sidebar'
import isMobile from '../modules/isMobile'
import NewsOverviewScreen from './news/NewsOverviewScreen'
import NoNewsScreen from './news/NoNewsScreen'
import NewsDetailScreen from './news/NewsDetailScreen'

export default function NewsScreen() {
  return (
    <div className="theme--news flex grow">

      {!isMobile() && (
      <Sidebar>
        <Routes>
          <Route
            path="/*"
            element={<NewsOverviewScreen />}
          />
        </Routes>
      </Sidebar>
      )}

      <PageContent>
        <Routes>
          <Route
            path="/:newsItemId"
            element={<NewsDetailScreen />}
          />
          <Route
            path="/*"
            element={isMobile() ? <NewsOverviewScreen /> : <NoNewsScreen />}
          />
        </Routes>
      </PageContent>

    </div>
  )
}
