import { useLingui } from '@lingui/react'

export default (fileExtension, fileName) => {
  const { i18n } = useLingui()

  switch (fileExtension) {
    case 'error': {
      return `❌ ${i18n._('file.description.error')}`
    }

    case 'document':
    case 'docx':
    case 'doc':
    case 'pptx':
    case 'ppt':
    case 'xlsx':
    case 'xls':
    case 'pdf':
    case 'pages':
      return `📄 ${fileName || i18n._('file.description.document')}`

    case 'image':
    case 'jpg':
    case 'jpeg':
    case 'png':
      return `📷︎ ${fileName || i18n._('file.description.image')}`

    case 'video':
    case 'mp4':
    case 'mov':
    case 'gif':
      return `🎬 ${fileName || i18n._('file.description.video')}`

    default:
      return `📎︎ ${fileName || i18n._('file.description.other')}`
  }
}
