import React from 'react'

import {
  ArrowsLoop,
  Document,
  Eye,
  XMark,
} from '@Infowijs-eng/component-library/icons'
import {
  Button,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import ArrowsClockwise from '@Infowijs-eng/component-library/icons/ArrowClockwise'
import PropTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import isMobile from '../../modules/isMobile'

function MessageComposerFilePreview({
  file, progress, uploadErrorMessage, onRetry, onCancel,
}) {
  return (
    <div className="h-8 bg-white flex items-center pl-2 pr-1 mr-2 rounded-full min-w-0">
      <div className="min-w-0">
        <WithAddon
          title={file.name}
          className="min-w-0"
          leadingAddon={<Document key="icon" fontSize="1.25rem" />}
          trailingAddon={[
            progress > 0 && !uploadErrorMessage && !isMobile() && (
            <div className="h-full flex items-center self-center px-4" key="progress">
              <div className="w-32 h-2 bg-gray-50 rounded-full">
                <div className={`h-full bg-theme-500 rounded-full w-${progress}% transition-[width]`} />
              </div>
              <span className="pl-2 text-gray-500 text-xs font-medium">
                {progress < 100 && `${progress}%`}
                {progress >= 100 && <Trans id="MessageComposerFilePreview.done" />}
              </span>
            </div>
            ),
            progress > 0 && !uploadErrorMessage && isMobile() && (
            <div className="h-full flex items-center self-center px-2" key="progress">
              {progress < 100 && <ArrowsLoop className="animate-spin" />}
              <span className="pl-2 text-gray-500 text-xs font-medium">
                {progress < 100 && `${progress}%`}
                {progress >= 100 && <Trans id="MessageComposerFilePreview.done" />}
              </span>
            </div>
            ),
            uploadErrorMessage && (
            <WithAddon
              key="retry"
              className="px-4 text-sm text-gray-500"
              trailingAddon={(
                <Button
                  small
                  neutral
                  leadingAddon={<ArrowsClockwise fontSize="1.25rem" />}
                  onClick={onRetry}
                />
              )}
            >
              <Trans id="MessageComposerFilePreview.error" />
            </WithAddon>
            ),
            file.url && (
            <Button
              key="preview"
              small
              neutral
              leadingAddon={<Eye fontSize="1.25rem" />}
              href={file.url}
              target="_blank"
            />
            ),
            <Button
              key="cancel"
              small
              neutral
              leadingAddon={<XMark fontSize="1.25rem" />}
              onClick={onCancel}
              className="text-red-500 hover:text-red-800"
            />,
          ]}
        >
          <span className="text-sm font-black truncate">
            {file.name}
          </span>
        </WithAddon>
      </div>
    </div>
  )
}

MessageComposerFilePreview.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  progress: PropTypes.number,
  uploadErrorMessage: PropTypes.string,
}

MessageComposerFilePreview.defaultProps = {
  progress: 0,
  uploadErrorMessage: null,
}

export default MessageComposerFilePreview
