import dayjs from 'dayjs'

export default (time, i18n) => {
  if (dayjs(time).year() === dayjs().year()) {
    return dayjs(time).calendar(dayjs(), {
      sameDay: i18n._('dateTime.sameDay'),
      lastDay: i18n._('dateTime.lastDay'),
      lastWeek: i18n._('dateTime.lastWeek'),
      sameElse: i18n._('dateTime.sameElse'),
    })
  }

  return dayjs(time).format(i18n._('dateTime.sameElseOtherYear'))
}
