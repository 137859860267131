import React from 'react'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import makeHasPermission from '@Infowijs-eng/component-library/modules/authentication/selectors/makeHasPermission'
import { useSelector } from 'react-redux'
import BackgroundBubbles from '../../components/BackgroundBubbles'
import Placeholder from '../../components/Placeholder'
import hintGraphic from '../../images/conversation_hint.svg'

function NoMessageScreen() {
  const { i18n } = useLingui()

  const hasPermission = makeHasPermission()
  const hasAccessToCompose = useSelector((state) => hasPermission(state, {
    permissionsWithRegex: 'hoy.messages.create|clients.hoy_web',
  }))

  return (
    <BackgroundBubbles>
      <div className="h-full flex items-center justify-center p-16">
        {
          hasAccessToCompose && (
            <Placeholder
              image={hintGraphic}
              title={i18n._('messages.details.empty.title')}
            >
              <p className="mt-2 whitespace-pre text-center">
                <Trans id="messages.details.empty.description">
                  In this web version, you can only reply to existing conversations.
                  To start a new conversation, use the app.
                </Trans>
              </p>
            </Placeholder>
          )
        }
        {
          !hasAccessToCompose && (
            <Placeholder
              image={hintGraphic}
              title={i18n._('messages.details.empty.title.alternative')}
            >
              <p className="mt-2 whitespace-pre text-center">
                <Trans id="messages.details.empty.description.alternative">
                  In this web version, you can only reply to existing conversations.
                  To start a new conversation, use the app.
                </Trans>
              </p>
            </Placeholder>
          )
        }
      </div>
    </BackgroundBubbles>
  )
}

export default NoMessageScreen
