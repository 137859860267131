const colors = [
  'from-orange-700 bg-orange-500',
  'from-green-700 bg-green-500',
  'from-blue-700 bg-blue-500',
  'from-purple-700 bg-purple-500',
  'from-red-700 bg-red-500',
  'from-turquoise-700 bg-turquoise-500',
  'from-gray-700 bg-gray-500',
]

export default (number) => colors[Number(number) % colors.length]
