import parse, { domToReact } from 'html-react-parser'
import { Heading } from '@Infowijs-eng/component-library/components'
import React from 'react'
import * as DOMPurify from 'dompurify'

const purifyContent = (content) => {
  const allowedTags = [
    'H1', 'H2', 'H3', 'p', 'span', 'strong', 'em', 'ol', 'ul', 'li', 'img', 'blockquote', 'a', 'figure',
  ]
  const allowedUriTags = ['a', 'p', 'blockquote']
  return DOMPurify.sanitize(content, {
    ALLOWED_TAGS: allowedTags,
    ADD_DATA_URI_TAGS: allowedUriTags,
  })
}

// noinspection JSUnusedGlobalSymbols
/* eslint-disable consistent-return */
const transformer = {
  replace: (node) => {
    const {
      name,
      attribs,
      children,
    } = node

    if (name && (!children || children.length === 0) && !attribs) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>
    }

    switch (name) {
      case 'a':
        return (
          <a className="underline text-theme-500 hover:text-theme-600" href={attribs.href} target={attribs.target}>
            {domToReact(children, transformer)}
          </a>
        )
      case 'ul':
        return <ul className="list-disc list-inside text-base">{domToReact(children, transformer)}</ul>
      case 'ol':
        return <ol className="list-decimal list-inside text-base">{domToReact(children, transformer)}</ol>
      case 'p':
        return <div className="text-base leading-relaxed">{domToReact(children, transformer)}</div>
      case 'h1':
        return <Heading fontSize={Heading.size.H2}>{domToReact(children, transformer)}</Heading>
      case 'h2':
        return <Heading fontSize={Heading.size.H3}>{domToReact(children, transformer)}</Heading>
      case 'h3':
        return <Heading fontSize={Heading.size.H4}>{domToReact(children, transformer)}</Heading>
      case 'img':
        return (
          <div className="flex justify-center">
            <img className="max-h-[400px] bg-contain" alt={attribs.alt} src={attribs.src} />
          </div>
        )
      case 'figure':
        return domToReact(children, transformer)
      case 'blockquote':
        return (
          <blockquote className="px-4 py-2 my-2 border-l-4 border-gray-300 dark:border-gray-500 dark:bg-gray-800">
            {domToReact(children, transformer)}
          </blockquote>
        )
      default:
    }
  },
}

export default (content) => parse(purifyContent(content), transformer)
