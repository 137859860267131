import { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'

import {
  getAccessToken,
  getRandomSessionIdentifier,
  isIframeReady,
  shouldRedirectToLogin,
  signOut,
  startAccessTokenRefresh,
  verifyTransferToken,
} from '@Infowijs-eng/component-library/modules/authentication'
import {
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import setRandomSessionIdentifier
  from '@Infowijs-eng/component-library/modules/authentication/actions/setRandomSessionIdentifier'
import getLoginEndpoint from '../modules/getLoginEndpoint'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function doRedirectToLogin() {
  let productName = null
  if (window.location.host !== 'beta.hoyapp.nl' && window.location.host !== 'beta.staging.hoyapp.nl') {
    productName = window.location.host?.split('.')?.[0]
  }

  if (productName) {
    window.location = `${getLoginEndpoint()}/${productName}/hoy`
  } else {
    window.location = `${getLoginEndpoint()}`
  }
}

function removeQueryParams(navigate, queryParams) {
  queryParams.delete('rsi')
  queryParams.delete('token')
  navigate({
    search: queryParams.toString(),
  }, {
    replace: true,
  })
}

function AuthenticationGate({ children }) {
  const navigate = useNavigate()
  const queryParams = useQuery()
  const dispatch = useDispatch()
  const promisifyDispatch = usePromisifyDispatch()

  const isAuthenticationFrameLoaded = useSelector(isIframeReady)
  const hasAccessToken = useSelector(getAccessToken)
  const currentRsi = useSelector(getRandomSessionIdentifier)
  const redirectToLogin = useSelector(shouldRedirectToLogin)

  const suppliedRsi = queryParams.get('rsi')
  const token = queryParams.get('token')

  useEffect(() => {
    if (!isAuthenticationFrameLoaded) {
      return
    }

    if (currentRsi && !suppliedRsi && !token) {
      dispatch(startAccessTokenRefresh())
      return // Nothing to do
    }

    if (currentRsi && currentRsi === suppliedRsi) {
      dispatch(startAccessTokenRefresh())
      removeQueryParams(navigate, queryParams)
      return
    }

    if (!token && !suppliedRsi) {
      // Not signed in and no info to sign in
      doRedirectToLogin()
      return
    }

    removeQueryParams(navigate, queryParams)

    async function triggerLogin() {
      if (currentRsi) {
        dispatch(signOut(currentRsi))
      }

      if (suppliedRsi) {
        dispatch(setRandomSessionIdentifier(suppliedRsi))
        dispatch(startAccessTokenRefresh())
        return
      }

      try {
        await promisifyDispatch(verifyTransferToken(token))
      } catch (e) {
        console.log(e)
        doRedirectToLogin()
      }
    }

    triggerLogin().then()
  }, [
    isAuthenticationFrameLoaded,
  ])

  useEffect(() => {
    if (redirectToLogin) {
      doRedirectToLogin()
    }
  }, [redirectToLogin])

  if (hasAccessToken) {
    return children
  }

  return null
}

AuthenticationGate.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthenticationGate
