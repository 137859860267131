import { Skeleton } from '@Infowijs-eng/component-library/components'
import React from 'react'

export default function NewsDetailScreenSkeleton() {
  return (
    <div className="text-left flex items-center flex-col space-y-10">
      <Skeleton className="w-full 2xl:w-7/12 2xl:mt-10 h-full aspect-video 2xl:rounded-3xl" />
      <div className="w-3/4 lg:w-1/2">
        <Skeleton className="h-11 lg:h-14 w-full" />
        <Skeleton className="h-2 lg:h-4 w-10" />
      </div>
    </div>
  )
}
