import { ConversationMember, DisabledMessageType } from '@Infowijs-eng/hoy_shared/modules/messages/types'
import { t } from '@lingui/macro'
import getFullName from '@Infowijs-eng/hoy_shared/modules/getFullName'

const translationMap = (fullNameSender : string) => ({
  [DisabledMessageType.TEST]: t`This test message will disappear from your inbox automatically after 60 minutes.`,
  [DisabledMessageType.LOADING]: t`Loading...`,
  [DisabledMessageType.ERROR]: t`Something went wrong, please contact support@infowijs.nl`,
  [DisabledMessageType.CLOSED]: t`Something went wrong, please contact support@infowijs.nl`,
  [DisabledMessageType.ARCHIVED_CONVERSATION]: t`This chat is archived.`,
  [DisabledMessageType.CLOSED_BY_OWNER]: t`${fullNameSender} has archived the group.`,
  [DisabledMessageType.CLOSED_BY_OWNER_MULTICAST]: t`${fullNameSender} has closed the group.`,
  [DisabledMessageType.ARCHIVED_MULTICAST]: t`This chat is archived.`,
  [DisabledMessageType.LEFT]: t`You have left the group.`,
  [DisabledMessageType.REMOVED]: t`You are no longer a participant in this conversation`,
  [DisabledMessageType.ANNOUNCEMENT]: t`You can't reply to announcements.`,
})

export default function getDisabledChatHintTranslation(
  disabledMessageType: DisabledMessageType | null,
  conversationOwner: ConversationMember | null,
) {
  if (!disabledMessageType) {
    return null
  }

  const owner = conversationOwner ? getFullName(conversationOwner) : ''

  if (disabledMessageType in translationMap(owner)) {
    return translationMap(owner)[disabledMessageType]
  }

  return t`Something went wrong, please contact support@infowijs.nl`
}
