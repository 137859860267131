import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { getAccessToken } from '@Infowijs-eng/component-library/modules/authentication'
import { getHoyEndpoint, serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import setUser from '../../actions/user/setUser'
import actionTypes from '../../action-types'
import getApiHeaders from '../../modules/getApiHeaders'
import getCustomerProductName from '../../selectors/customerProduct/getCustomerProductName'
import setDesign from '../../actions/customerProduct/setDesign'

const onFetchUser = function* onFetchUser() {
  yield takeLatest(actionTypes.USER.FETCH, function* onFetchUserSaga(action) {
    const accessToken = yield select(getAccessToken)
    const customerProductName = yield select(getCustomerProductName)
    const userProfileEndpoint = getHoyEndpoint(customerProductName)
    const customersEndpoint = serviceEndpoints.customers

    let response
    try {
      response = yield call(axios, {
        url: `${userProfileEndpoint}/hoy/v1/account/profile`,
        method: 'GET',
        headers: getApiHeaders({
          Authorization: `Bearer ${accessToken}`,
        }),
      })
    } catch (e) {
      rejectAction(action, response.data.errors)
      return
    }

    if (response && response.data && response.data.data) {
      const { data } = response.data
      const productName = data && data.community && data.community.name

      if (productName) {
        const customerProductMetaResponse = yield call(axios, {
          url: `${customersEndpoint}/products/hoy/${productName}`,
          method: 'GET',
        })

        if (customerProductMetaResponse.data
                && customerProductMetaResponse.data.data
                && customerProductMetaResponse.data.data.design) {
          const { design } = customerProductMetaResponse.data.data
          yield put(setDesign(design))
        }
      }

      yield put(setUser(data))
      acceptAction(action, data)
    }
  })
}

export default onFetchUser
