import PropTypes from 'prop-types'
import React from 'react'
import {
  BackNavigation,
  Clickable,
  Skeleton,
} from '@Infowijs-eng/component-library/components'
import { Trans } from '@lingui/macro'
import {
  useLocation,
  useMatch,
  useParams,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useLingui } from '@lingui/react'
import { ConversationType } from '@Infowijs-eng/hoy_shared/modules/messages/types'
import { getConversation } from '@Infowijs-eng/hoy_shared/modules/messages/selectors'
import MessageGroup from './MessageGroup'

function MessageGroupBccHeader({ inboxId }) {
  const { search } = useLocation()
  const inArchive = !!useMatch('/messages/archive/*')
  const { conversationId } = useParams()
  const { i18n } = useLingui()

  const conversation = useSelector((state) => getConversation(state, {
    inboxId,
    conversationId,
  }))

  let lastMessageContent = ''

  if (conversation?.type === ConversationType.Conversation) {
    lastMessageContent = (
      <Trans id="messageGroup.bcc.lastMessageContent.chat">
        Reactions can be found in the chats down below.
      </Trans>
    )
  }
  if (conversation?.type === ConversationType.Announcement) {
    lastMessageContent = (
      <Trans id="messageGroup.bcc.lastMessageContent.announcement">
        Recipients cannot respond to announcements
      </Trans>
    )
  }

  let path = ''
  if (conversation) {
    path = `/messages/${inArchive ? 'archive/' : ''}bcc/${conversation.id}/all`
  }
  const isActive = !!useMatch(path)

  return (
    <div className="sticky top-0 bg-white z-10">
      <div className="pt-10 pb-6 px-10 border-b border-gray-100 space-y-3">
        <BackNavigation
          label={inArchive ? i18n._('messageGroup.bcc.backToArchive') : i18n._('messageGroup.bcc.backToInbox')}
          to={{
            pathname: inArchive ? '/messages/archive' : '/messages',
            search,
          }}
        />

        <h1 className="text-h5 leading-6 font-extrabold font-display text-gray-900">
          {!conversation && <Skeleton lg className="w-1/3" />}
          {conversation?.title}
        </h1>
      </div>
      <div className="py-3 px-10 border-b border-gray-100">
        <Clickable
          to={{
            pathname: conversation && `/messages/${inArchive ? 'archive/' : ''}bcc/${conversation.id}/all`,
            search,
          }}
          className="w-full"
        >
          <MessageGroup
            type={conversation?.type}
            userCount={conversation?.numUsers && conversation.numUsers - 1}
            title={conversation?.title}
            lastMessageContent={conversation ? lastMessageContent : <Skeleton sm className="w-2/3" />}
            updatedAt={conversation?.updatedAt}
            isActive={isActive}
          />
        </Clickable>
      </div>

      <h3 className="pt-6 px-10 text-h6 uppercase font-extrabold text-xs">
        <Trans id="messageGroup.bcc.header.messages">Losse berichten</Trans>
      </h3>
    </div>
  )
}

MessageGroupBccHeader.propTypes = {
  inboxId: PropTypes.string.isRequired,
}

export default MessageGroupBccHeader
