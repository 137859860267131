import React from 'react'
import PropTypes from 'prop-types'

import { NavLink } from 'react-router-dom'
import { Ellipsis } from '@Infowijs-eng/component-library//icons'
import {
  Button,
  Dropdown,
  DropdownItem,
} from '@Infowijs-eng/component-library/components'
import { Visibility } from '@Infowijs-eng/component-library/modules/authentication'
import { cn } from '@Infowijs-eng/component-library/modules'

function Header({
  children,
  subNavigation,
  actions,
}) {
  return (
    <div
      className={cn(
        'h-20 px-10 flex flex-col',
        subNavigation && 'h-32 border-b border-gray-100',
      )}
    >
      <div className="pt-8 h-20 flex sm:justify-between items-center space-x-2 lg:space-x-0">
        <div className="flex-1">
          <h1 className="text-h5 leading-6 font-extrabold font-display text-gray-900">
            {children}
          </h1>
        </div>
        <div className="hidden sm:block flex-shrink-0">
          {actions && (
            <div className="mt-3 flex flex-row-reverse sm:mt-0 sm:ml-4 space-x-2 space-x-reverse">
              {actions.map(({ permissions, key, ...action }) => (
                <Visibility key={key} permissions={permissions}>
                  <Button
                    small
                    {...action /* eslint-disable-line react/jsx-props-no-spreading */}
                  />
                </Visibility>
              ))}
            </div>
          )}
        </div>
        <div className="sm:hidden flex-shrink-0">
          {actions && (
            actions.length > 1 ? (
              <div className="mt-3 sm:mt-0 sm:ml-4">
                <Dropdown
                  buttonProps={{
                    primary: true,
                    small: true,
                  }}
                  Icon={Ellipsis}
                  alignRight
                >
                  {actions.map(({ permissions, key, ...action }) => (
                    <Visibility key={key} permissions={permissions}>
                      <DropdownItem
                        {...action /* eslint-disable-line react/jsx-props-no-spreading */}
                      />
                    </Visibility>
                  ))}
                </Dropdown>
              </div>
            ) : (
              <div className="sm:mt-0 sm:ml-4">
                {actions.map(({
                  permissions, key, children: c, ...action
                }) => (
                  <Visibility key={key} permissions={permissions}>
                    <Button
                      small
                      {...action /* eslint-disable-line react/jsx-props-no-spreading */}
                      title={c}
                    />
                  </Visibility>
                ))}
              </div>
            )
          )}
        </div>
      </div>
      {subNavigation && (
        <nav className="flex space-x-6">
          {subNavigation.map(({ key, isActive, ...navItem }) => (
            <NavLink
              key={key || navItem.to}
              {...navItem /* eslint-disable-line react/jsx-props-no-spreading */}
              className={({ isActive: isPathActive }) => cn(
                'border-transparent text-gray-400 hover:text-gray-900 hover:border-gray-400 whitespace-nowrap',
                'pb-2 px-1 pt-4 border-b font-display font-extrabold',
                (isActive || isPathActive) ? 'text-gray-900 border-gray-900' : null,
              )}
            />
          ))}
        </nav>
      )}
    </div>
  )
}

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  search: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  onSearchSubmit: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    children: PropTypes.string,
    trailingAddon: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
    onClick: PropTypes.func,
    to: PropTypes.string,
    primary: PropTypes.bool,
    neutral: PropTypes.bool,
  })),
  subNavigation: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    to: PropTypes.string,
    exact: PropTypes.bool,
  })),
}

Header.defaultProps = {
  search: null,
  searchPlaceholder: null,
  subNavigation: null,
  actions: null,
  onSort: () => {
  },
  onSearch: null,
  onSearchSubmit: () => {
  },
}

export default Header
