import { createSelector } from 'reselect'
import getAccessToken from '@Infowijs-eng/component-library/modules/authentication/selectors/getAccessToken'
import decodeJWT from '@Infowijs-eng/component-library/modules/authentication/modules/decodeJWT'

export default createSelector(
  getAccessToken,
  (accessToken: string): string | null => {
    if (!accessToken) {
      return null
    }
    const principal = decodeJWT(accessToken)
    return principal && principal.user && principal.user.id
  },
)
