import { PropsWithChildren, useEffect } from 'react'
import { i18n } from '@lingui/core'
import { en, nl } from 'make-plural'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'

import { useSelector } from 'react-redux'
import { I18nProvider } from '@lingui/react'
import { messages as messagesNl } from '../locales/nl_NL/messages'
import { messages as messagesEn } from '../locales/en_US/messages'
import getLanguage from '../selectors/user/getLanguage'

require('dayjs/locale/en')

i18n.loadLocaleData('nl-NL', { plurals: nl })
i18n.loadLocaleData('en-US', { plurals: en })
i18n.load('nl-NL', messagesNl)
i18n.load('en-US', messagesEn)
i18n.activate('en-US')
dayjs.extend(calendar)
dayjs.locale('en')

export default function LocaleProvider({
  children = null,
}: PropsWithChildren) {
  const language = useSelector(getLanguage)

  useEffect(() => {
    if (language) {
      switch (language) {
        case 'nl_NL':
          // eslint-disable-next-line global-require
          require('dayjs/locale/nl')
          dayjs.locale('nl')
          i18n.activate('nl-NL')
          break
        default:
          // eslint-disable-next-line global-require
          dayjs.locale('en')
          i18n.activate('en-US')
          break
      }
    }
  }, [language])

  return (
    <I18nProvider i18n={i18n}>
      {children}
    </I18nProvider>
  )
}
