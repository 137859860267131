import React from 'react'

import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  Avatar,
} from '@Infowijs-eng/component-library/components'
import { cn } from '@Infowijs-eng/component-library/modules'
import MessageBubble from './MessageBubble'

function Message({
  type,
  avatar,
  content,
  createdAt,
  userTitle,
  sentByMe,
  showAuthor,
  showDate,
  showTime,
}) {
  return (
    <div
      className="flex flex-col"
      title={`${userTitle ? `${userTitle} - ` : ''}${dayjs(createdAt).format('DD-MM-YYYY HH:mm')}`}
    >
      {showDate && (
      <span className="py-2 sm:py-4 self-center text-gray-500 text-xs font-bold uppercase tracking-widest">
        {createdAt && dayjs(createdAt).format('DD MMMM YYYY')}
      </span>
      )}
      <div className={cn('flex', sentByMe ? 'flex-row-reverse ml-8' : 'mr-8')}>
        {showAuthor && avatar && (
        <div className="flex-shrink-0 mt-5 mr-4">
          <Avatar id={avatar.id} type={avatar.type} />
        </div>
        )}
        <div className={cn('flex flex-col', avatar && !showAuthor && 'pl-14')}>
          {showAuthor && userTitle && (
            <span className="text-gray-500 font-bold text-xs">
              {userTitle}
            </span>
          )}
          <MessageBubble
            type={type}
            content={content}
            sentByMe={sentByMe}
            showTail={showAuthor}
          />
          {showTime && (
            <span className={cn('pb-4 text-gray-500 text-xs font-bold mt-2', sentByMe && 'self-end')}>
              {createdAt && dayjs(createdAt).format('HH:mm')}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

Message.propTypes = {
  type: PropTypes.number,
  avatar: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    }),
  ]),
  createdAt: PropTypes.string,
  userTitle: PropTypes.string,
  sentByMe: PropTypes.bool,
  showAuthor: PropTypes.bool,
  showDate: PropTypes.bool,
  showTime: PropTypes.bool,
}

Message.defaultProps = {
  type: 1,
  content: null,
  avatar: null,
  createdAt: null,
  userTitle: null,
  sentByMe: false,
  showAuthor: false,
  showDate: false,
  showTime: false,
}

export default Message
