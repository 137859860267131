import {
  cn,
} from '@Infowijs-eng/component-library/modules'

export interface MenuDividerProps {
  className?: string // TODO: Replace when component lib is updated to 29.0
}
export default function MenuDivider({ className = null }: MenuDividerProps) {
  return (
    <div className={cn(
      'w-full border-t border-white md:border-gray-50',
      className,
    )}
    />
  )
}
