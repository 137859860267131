import React from 'react'
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { PrivateScreen } from '@Infowijs-eng/component-library/modules/authentication'
import MessagesScreen from './screens/MessagesScreen'
import NewsScreen from './screens/NewsScreen'

export default function routes() {
  return (
    <Routes>
      <Route
        path="/news/*"
        element={(
          <PrivateScreen
            screen={<NewsScreen />}
            permissionsWithRegex="(hoy.news.list|clients.hoy_web)"
          />
        )}
      />
      <Route
        path="/messages/*"
        element={(
          <PrivateScreen
            screen={<MessagesScreen />}
            permissionsWithRegex="(hoy.messages.list|clients.hoy_web)"
            onNotPermittedRedirect="/news"
          />
        )}
      />
      <Route path="/" element={<Navigate to="news" />} />
    </Routes>
  )
}
