import PropTypes from 'prop-types'
import React from 'react'

import {
  useMatch,
} from 'react-router-dom'
import {
  useSelector,
} from 'react-redux'
import { getHoyEndpoint } from '@Infowijs-eng/component-library/modules'
import {
  ArrowsLoop,
  PencilSquare,
} from '@Infowijs-eng/component-library/icons'
import { Trans } from '@lingui/macro'
import { WithAddon } from '@Infowijs-eng/component-library/components'
import makeHasPermission from '@Infowijs-eng/component-library/modules/authentication/selectors/makeHasPermission'
import {
  getRandomSessionIdentifier,
} from '@Infowijs-eng/component-library/modules/authentication'
import Header from '../Header'
import getCustomerProductName from '../../selectors/customerProduct/getCustomerProductName'

function ConversationHeader({ loading }) {
  const hasPermission = makeHasPermission()
  const match = useMatch('/messages/*')
  const customerProductName = useSelector(getCustomerProductName)
  const inArchive = match?.pathname?.indexOf('messages/archive') === 1

  const canCreateConversations = useSelector((state) => hasPermission(state, {
    permissions: ['hoy.messages.create'],
  }))
  const rsi = useSelector(getRandomSessionIdentifier)

  return (
    <div className="sticky top-0 bg-white z-10">
      <Header
        actions={[
          canCreateConversations && {
            primary: true,
            key: 'create',
            leadingAddon: <PencilSquare />,
            small: false,
            href: `${getHoyEndpoint(customerProductName)}/messages/add?rsi=${rsi}&teacher=true`,
          },
        ].filter(Boolean)}
        subNavigation={[
          {
            key: 'inbox',
            children: <Trans id="messageGroups.inbox.title">Inbox</Trans>,
            to: '/messages',
            end: true,
            isActive: !inArchive,
          },
          {
            key: 'archive',
            children: <Trans id="messageGroups.archive.title">Archive</Trans>,
            to: '/messages/archive',
            isActive: inArchive,
          },
        ]}
      >
        <WithAddon
          trailingAddon={loading && <ArrowsLoop className="animate-spin" />}
          className="px-0"
          fullWidth
        >
          <div className="w-full">
            <Trans id="messages.title">Messages</Trans>
          </div>
        </WithAddon>
      </Header>
    </div>
  )
}

ConversationHeader.propTypes = {
  loading: PropTypes.bool,
}

ConversationHeader.defaultProps = {
  loading: false,
}

export default ConversationHeader
