import React from 'react'
import PropTypes from 'prop-types'

import {
  email,
  strictUrl,
  url,
} from '../../modules/urlRegex'

const testEmailOrUrl = (input) => email.test(input)
  || input.toLowerCase().startsWith('http://')
  || input.toLowerCase().startsWith('https://')
  || input.toLowerCase().startsWith('www.')
  || url.test(input)

const testEmailOrStrictUrl = (word) => email.test(word)
  || word.toLowerCase().startsWith('http://')
  || word.toLowerCase().startsWith('https://')
  || word.toLowerCase().startsWith('www.')
  || strictUrl.test(word)

function MessageParser({ text }) {
  if (!testEmailOrUrl(text)) {
    return text
  }

  return text.split(/(\s+)/).map((word, index) => {
    if (testEmailOrStrictUrl(word)) {
      let link = word

      if (email.test(word)) {
        link = `mailto:${word}`
      } else if (!word.toLowerCase().startsWith('http')) {
        link = `https://${word}`
      }

      return (
        <a
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          href={link}
          className="underline break-all"
          target="_blank"
          rel="noreferrer"
        >
          {word}
        </a>
      )
    }
    return word
  })
}

MessageParser.propTypes = {
  text: PropTypes.string.isRequired,
}

export default MessageParser
