import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import getEnvironment from './modules/environment'
import '@Infowijs-eng/component-library/theme/infowijs.css'
import '@Infowijs-eng/component-library/theme/hoy.css'
import App from './components/App'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://1964bc0814f34df4a10b65a369e3be11@o97448.ingest.sentry.io/5748855',
    // eslint-disable-next-line no-nested-ternary
    environment: getEnvironment(),
    release: `hoy-web@${process.env.VERSION}`,
  })
}

const root = createRoot(document.getElementById('root'))

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<p>Something went wrong</p>}>
      <App />
    </Sentry.ErrorBoundary>
  </StrictMode>,
)
