import isStaging from '@Infowijs-eng/component-library/modules/isStaging'
import isDevelopment from '@Infowijs-eng/component-library/modules/isDevelopment'

export default () => {
  const tld = process.env.INFOWIJS_TLD || (isDevelopment && 'test') || 'nl'

  if (isStaging) {
    return `https://login.staging.infowijs.${tld}`
  }

  return `https://login.infowijs.${tld}`
}
