import decodeJWT from '@Infowijs-eng/component-library/modules/authentication/modules/decodeJWT'
import authActionTypes from '@Infowijs-eng/component-library/modules/authentication/actionTypes'
import actionTypes from '../action-types'

export default (state = { loading: false }, action = {}) => {
  switch (action.type) {
    case actionTypes.USER.FETCH:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.USER.SET: {
      return {
        ...state,
        loading: false,
        avatar: action.payload.avatar,
        email: action.payload.email,
        fullName: action.payload.title,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        subtitle: action.payload.subtitle,
      }
    }
    case authActionTypes.SET_ACCESS_TOKEN: {
      const tokenContent = decodeJWT(action.payload)
      return {
        ...state,
        ...tokenContent.user,
        language: tokenContent.language,
      }
    }
    default: {
      return state
    }
  }
}
