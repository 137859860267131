// Original found on https://github.com/kevva/url-regex, but small enough to avoid use of dependency

const protocol = '(?:(?:[a-z]+:)?//)?'
const auth = '(?:\\S+(?::\\S*)?@)?'
const ipV4 = '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}'
const host = '(?:(?:[a-z\\u00a1-\\uffff0-9][-_]*)*[a-z\\u00a1-\\uffff0-9]+)'
const domain = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*'
const tld = '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,3}))\\.?'
const port = '(?::\\d{2,5})?'
const path = '(?:[/?#][^\\s"]*)?'

const urlRegex = `(?:${protocol}|www\\.)${auth}(?:localhost|${ipV4}|${host}${domain}${tld})${port}${path}`
const emailRegex = `@(?:localhost|${ipV4}|${host}${domain}${tld})`

export const url = new RegExp(`(?:${urlRegex})`, 'i')
export const strictUrl = new RegExp(`(?:^${urlRegex})$`, 'i')
export const email = new RegExp(`(?:${emailRegex})`, 'i')
