import React from 'react'
import {
  Route,
  Routes,
} from 'react-router-dom'
import PageContent from '../components/PageContent'
import Sidebar from '../components/Sidebar'
import MessagesDetailScreen from './messages/MessagesDetailScreen'
import NoMessageScreen from './messages/NoMessageScreen'
import NoMessageBccScreen from './messages/NoMessageBccScreen'
import isMobile from '../modules/isMobile'
import MessagesInboxScreen from './messages/MessagesInboxScreen'
import MessagesArchiveScreen from './messages/MessagesArchiveScreen'
import MessagesBccScreen from './messages/MessagesBccScreen'

function MessagesScreen() {
  return (
    <div className="theme--messages flex grow">
      {!isMobile() && (
        <Sidebar>
          <Routes>
            <Route
              path="/archive/bcc/:conversationId/:recipient?"
              element={<MessagesBccScreen />}
            />
            <Route
              path="/archive/:conversationId?/*"
              element={<MessagesArchiveScreen />}
            />
            <Route
              path="/bcc/:conversationId/:recipient?"
              element={<MessagesBccScreen />}
            />
            <Route
              path="/:conversationId?/*"
              element={<MessagesInboxScreen />}
            />
          </Routes>
        </Sidebar>
      )}

      <PageContent>
        <Routes>
          <Route path="/archive/:conversationId" element={<MessagesDetailScreen />} />
          <Route path="/archive/bcc/:conversationId/:recipient" element={<MessagesDetailScreen />} />
          <Route
            path="/archive/bcc/:conversationId"
            element={isMobile() ? <MessagesBccScreen /> : <NoMessageBccScreen />}
          />
          <Route path="/archive" element={isMobile() ? <MessagesArchiveScreen /> : <NoMessageScreen />} />
          <Route path="/bcc/:conversationId/:recipient" element={<MessagesDetailScreen />} />
          <Route
            path="/bcc/:conversationId"
            element={isMobile() ? <MessagesBccScreen /> : <NoMessageBccScreen />}
          />
          <Route path="/:conversationId" element={<MessagesDetailScreen />} />
          <Route
            path="/*"
            element={isMobile() ? <MessagesInboxScreen /> : <NoMessageScreen />}
          />
        </Routes>
      </PageContent>
    </div>
  )
}

export default MessagesScreen
