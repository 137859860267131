import React, {
  useEffect,
  useRef,
} from 'react'
import {
  useParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchConversationMessages,
  fetchConversationMeta,
} from '@Infowijs-eng/hoy_shared/modules/messages/actions'
import {
  getFetchConversationMessagesRequestStatus,
  getFetchConversationMetaRequestStatus,
} from '@Infowijs-eng/hoy_shared/modules/messages/selectors/status'
import {
  makeGetVirtualConversations,
} from '@Infowijs-eng/hoy_shared/modules/messages/selectors'
import { useConversationAutoRefresh } from '@Infowijs-eng/hoy_shared/modules/messages/hooks'
import ConversationBccHeader from '../../components/messages/ConversationBccHeader'
import ConversationList from '../../components/messages/ConversationList'
import useWindowInFocus from '../../hooks/useWindowInFocus.ts'
import getUserId from '../../sagas/user/getUserId.ts'

function MessagesBccScreen() {
  const ref = useRef(null)
  const { conversationId } = useParams()
  const dispatch = useDispatch()

  const userId = useSelector(getUserId)
  const getVirtualConversations = makeGetVirtualConversations(getUserId)
  const virtualConversations = useSelector((state) => getVirtualConversations(state, {
    inboxId: userId,
    conversationId,
  }))
  const conversationMessagesRequestStatus = useSelector((state) => getFetchConversationMessagesRequestStatus(state, {
    inboxId: userId,
    conversationId,
  }))
  const conversationMetaRequestStatus = useSelector((state) => getFetchConversationMetaRequestStatus(state, {
    inboxId: userId,
    conversationId,
  }))

  useEffect(() => {
    if (!userId || !conversationId) {
      return
    }
    dispatch(fetchConversationMeta({
      inboxId: userId,
      conversationId,
    }))
    dispatch(fetchConversationMessages({
      inboxId: userId,
      conversationId,
    }))
  }, [userId, conversationId])

  const inFocus = useWindowInFocus()
  useConversationAutoRefresh(userId, conversationId, inFocus)

  const errors = (conversationMessagesRequestStatus.errors || conversationMetaRequestStatus.errors) ? (
    [...(conversationMetaRequestStatus.errors || []), ...(conversationMessagesRequestStatus.errors || [])]
  ) : null

  return (
    <div ref={ref}>
      <ConversationBccHeader inboxId={userId} />
      <ConversationList
        inboxId={userId}
        conversations={virtualConversations}
        loading={conversationMetaRequestStatus.isLoading || conversationMessagesRequestStatus.isLoading}
        errorMessages={errors}
      />
    </div>
  )
}

export default MessagesBccScreen
