import axios from 'axios'
import {
  getHoyEndpoint,
} from '@Infowijs-eng/component-library/modules'
import { useSelector } from 'react-redux'
import { getAccessToken } from '@Infowijs-eng/component-library/modules/authentication'
import getApiHeaders from '../modules/getApiHeaders'
import getCustomerProductName from '../selectors/customerProduct/getCustomerProductName'

const useFileUpload = () => {
  const accessToken = useSelector(getAccessToken)
  const customerProductName = useSelector(getCustomerProductName)
  const endpoint = getHoyEndpoint(customerProductName)

  return (file, onUploadProgress) => {
    const data = new FormData()
    data.append('file', file)

    return axios({
      url: `${endpoint}/hoy/v1/attachments`,
      method: 'POST',
      headers: getApiHeaders({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      }),
      data,
      onUploadProgress,
    })
  }
}

export default useFileUpload
