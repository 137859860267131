import React, {
  useEffect,
  useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchConversations } from '@Infowijs-eng/hoy_shared/modules/messages/actions'
import {
  getInboxConversations,
  getInboxMeta,
} from '@Infowijs-eng/hoy_shared/modules/messages/selectors'
import {
  getFetchInboxRequestStatus,
} from '@Infowijs-eng/hoy_shared/modules/messages/selectors/status'
import { useConversationsAutoRefresh } from '@Infowijs-eng/hoy_shared/modules/messages/hooks'
import usePageParam from '../../hooks/usePageParam'
import ConversationList from '../../components/messages/ConversationList'
import ConversationHeader from '../../components/messages/ConversationHeader'
import useWindowInFocus from '../../hooks/useWindowInFocus.ts'
import getUserId from '../../sagas/user/getUserId.ts'

function MessageInboxScreen() {
  const dispatch = useDispatch()
  const pageParam = usePageParam()
  const userId = useSelector(getUserId)
  const inboxConversations = useSelector((state) => getInboxConversations(state, { inboxId: userId }))
  const inboxMeta = useSelector((state) => getInboxMeta(state, { inboxId: userId }))
  const fetchInboxRequestStatus = useSelector((state) => getFetchInboxRequestStatus(state, { inboxId: userId }))
  const ref = useRef(null)

  useEffect(() => {
    dispatch(fetchConversations({
      inboxId: userId,
      offset: (Math.max(0, pageParam - 1) * 50),
    }))
  }, [pageParam])

  const inFocus = useWindowInFocus()
  useConversationsAutoRefresh(userId, false, inFocus)

  return (
    <div ref={ref}>
      <ConversationHeader loading={fetchInboxRequestStatus.isLoading} />
      <ConversationList
        inboxId={userId}
        conversations={inboxConversations}
        meta={inboxMeta}
        loading={fetchInboxRequestStatus.isLoading}
        errorMessages={fetchInboxRequestStatus.errors}
      />
    </div>
  )
}

export default MessageInboxScreen
