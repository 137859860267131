import { PersistGate } from 'redux-persist/integration/react'
import { AuthenticationFrame } from '@Infowijs-eng/component-library/modules/authentication'
import { Provider } from 'react-redux'
import React, { useMemo } from 'react'
import { ErrorBoundary } from '@sentry/react'
import { Trans } from '@lingui/macro'
import { BrowserRouter } from 'react-router-dom'
import Routes from '../routes'
import { createPersistedStore } from '../store'
import LocaleProvider from './LocaleProvider'
import Themes from './Themes'
import NavigationBar from './NavigationBar'
import AuthenticationGate from './AuthenticationGate'

export default function App() {
  const { store, persistor } = useMemo(createPersistedStore, [])

  return (
    <Provider store={store}>
      <LocaleProvider>
        <ErrorBoundary
          fallback={
            <p><Trans>Something went wrong</Trans></p>
          }
        >
          <PersistGate persistor={persistor}>
            <AuthenticationFrame client="nl.infowijs.hoy.web" />
            <Themes />
            <BrowserRouter>
              <div className="bg-white antialiased">
                <NavigationBar />
                <AuthenticationGate>
                  <div className="pt-16 h-screen w-screen flex font-sans">
                    <Routes />
                  </div>
                </AuthenticationGate>
              </div>
            </BrowserRouter>
          </PersistGate>
        </ErrorBoundary>
      </LocaleProvider>
    </Provider>
  )
}
