import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Alert,
  Badge,
  Heading,
} from '@Infowijs-eng/component-library/components'
import { useLingui } from '@lingui/react'
import { cn } from '@Infowijs-eng/component-library/modules'
import dayjs from 'dayjs'
import convertHtmlToJsx from '../../modules/convertHtmlToJsx'
import NewsDetailScreenSkeleton from '../../components/news/NewsDetailScreenSkeleton'
import useIncludableRequest from '../../hooks/useIncludableRequest'
import newsPlaceholder from '../../images/news-placeholder.svg'
import getRelativeTime from '../../modules/getRelativeTime'
import restoreIncludableThumbnailCrop from '../../modules/restoreIncludableThumbnailCrop'

function NewsDetailScreen() {
  const { i18n } = useLingui()
  const { newsItemId } = useParams()
  const [fetchNewsDetails, content, isLoading, error] = useIncludableRequest()
  const [postNewsDetailView] = useIncludableRequest(
    {
      method: 'POST',
    },
  )

  useEffect(() => {
    fetchNewsDetails(`/hoy/v1/news/${newsItemId}`)
    postNewsDetailView(`/hoy/v1/news/${newsItemId}/view`)
  }, [newsItemId])

  if (isLoading) {
    return (
      <NewsDetailScreenSkeleton />
    )
  }

  if (error) {
    return (
      <Alert title={i18n._('news.detail.error')} />
    )
  }

  if (!content) {
    return (
      <Alert title={i18n._('news.detail.empty')} />
    )
  }

  // eslint-disable-next-line max-len
  let backgroundImage = `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${restoreIncludableThumbnailCrop(content.imageUrl) || newsPlaceholder})`

  if (content.source === 'instagram') {
    backgroundImage = `url(${restoreIncludableThumbnailCrop(content.imageUrl)})`
  }

  return (
    <div className="text-left flex items-center flex-col space-y-10">
      <div
        style={{
          backgroundImage,
        }}
        className={cn(
          'w-full flex-none bg-center aspect-video bg-cover bg-theme-500 bg-no-repeat',
          'xl:w-9/12 2xl:w-7/12 xl:mt-10 xl:rounded-3xl',
          content.source === 'instagram' && 'bg-contain bg-transparent',
        )}
      />

      <div className="w-3/4 2xl:w-1/2 pb-6 space-y-4">
        {content.group && <Badge className="bg-theme-500 w-fit mb-2">{content.group}</Badge>}
        <Heading className="text-ellipsis overflow-auto" fontSize={Heading.size.H2}>
          {content.title}
        </Heading>
        <div className="text-gray-500 font-black text-xs">
          {getRelativeTime(dayjs.unix((content.createdAt)), i18n)}
        </div>
        <div className="space-y-4">
          {convertHtmlToJsx(content.rawContent)}
        </div>
      </div>
    </div>

  )
}

export default NewsDetailScreen
