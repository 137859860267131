import { useSelector } from 'react-redux'
import { Theme } from '@Infowijs-eng/component-library/components'
import React from 'react'
import getDesignColors from '../selectors/customerProduct/getDesignColors'

export default function Themes() {
  const colors = useSelector(getDesignColors)
  return (
    <>
      {colors && colors.brand && <Theme selector=".theme--hoy" color={colors.brand} />}
      {colors && colors.news && <Theme selector=".theme--news" color={colors.news} />}
      {colors && colors.messages && <Theme selector=".theme--messages" color={colors.messages} />}
    </>
  )
}
