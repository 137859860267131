import React from 'react'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useSelector } from 'react-redux'
import makeHasPermission from '@Infowijs-eng/component-library/modules/authentication/selectors/makeHasPermission'
import newsPlaceholder from '../../images/no-news-placeholder.svg'
import newsPlaceholderAlternative from '../../images/news_hint.svg'
import BackgroundBubbles from '../../components/BackgroundBubbles'
import Placeholder from '../../components/Placeholder'
import getCustomerProduct from '../../selectors/customerProduct/getCustomerProduct'

function NoMessageScreen() {
  const { i18n } = useLingui()

  const customerProduct = useSelector(getCustomerProduct)
  const customerProductName = customerProduct?.title ?? 'Hoy'

  const hasPermission = makeHasPermission()
  const hasAccessToForms = useSelector((state) => hasPermission(state, {
    permissionsWithRegex: 'hoy.forms.list|clients.hoy_web',
  }))

  return (
    <BackgroundBubbles>
      <div className="h-full flex items-center justify-center p-16">
        {hasAccessToForms && (
          <Placeholder
            image={newsPlaceholder}
            title={i18n._('news.title', { name: customerProductName })}
          >
            <p className="mt-2 whitespace-pre text-center">
              <Trans id="news.details.empty.description">
                In de linkerzijbalk kan je het nieuws selecteren
              </Trans>
            </p>
          </Placeholder>
        )}
        {!hasAccessToForms && (
          <Placeholder
            image={newsPlaceholderAlternative}
            title={i18n._('news.details.empty.title', { name: customerProductName })}
          >
            <p className="mt-2 whitespace-pre text-center">
              <Trans id="news.details.empty.description.alternative">
                In deze webversie kun je het nieuws lezen en reageren op berichten.
                Formulieren invullen of de jaarkalender bekijken? Dat doe je in de app.
              </Trans>
            </p>
          </Placeholder>
        )}
      </div>
    </BackgroundBubbles>
  )
}

export default NoMessageScreen
