import React from 'react'
import PropTypes from 'prop-types'
import { cn } from '@Infowijs-eng/component-library/modules'

function BackgroundBubbles({ children }) {
  return (
    <div className={cn(
      'theme--customer',
      'relative -z-10 h-full w-full overflow-x-hidden overflow-y-hidden bg-gray-50',
      'before:z-10 before:content-[""] before:absolute before:w-[700px] before:h-[700px] before:rounded-full',
      'before:left-[-200px] before:top-[-500px] before:opacity-40 before:bg-white',
      'after:z-10 after:content-[""] after:absolute after:w-[350px] after:h-[350px] after:rounded-full',
      'after:right-[-50px] after:bottom-[-200px] after:opacity-10 after:bg-theme-400',
    )}
    >
      <div className="absolute top-0 bottom-0 left-0 right-0 z-0 overflow-y-auto">
        {children}
      </div>
    </div>
  )
}

BackgroundBubbles.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

BackgroundBubbles.defaultProps = {}

export default BackgroundBubbles
