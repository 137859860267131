import { Avatar, Skeleton, WithAddon } from '@Infowijs-eng/component-library/components'
import React from 'react'
import CustomerProductAvatar from './CustomerProductAvatar'

export default function NavigationBarSkeleton() {
  return (
    <div className="flex items-center justify-between h-16 px-6 lg:px-10 border-b border-gray-100">
      <div className="flex items-center space-x-8">
        <CustomerProductAvatar />
        <WithAddon leadingAddon={<Skeleton icon />} leadingAddonClassName="text-2xl">
          <Skeleton className="w-[80px]" />
        </WithAddon>
        <WithAddon leadingAddon={<Skeleton icon />} leadingAddonClassName="text-2xl">
          <Skeleton className="w-[80px]" />
        </WithAddon>
      </div>
      <div className="flex items-center space-x-8 text-sm">
        <WithAddon leadingAddon={<Skeleton icon />} leadingAddonClassName="text-2xl">
          <Skeleton className="w-[100px]" />
        </WithAddon>
        <Avatar />
      </div>
    </div>
  )
}
