import React from 'react'
import PropTypes from 'prop-types'
import { useLingui } from '@lingui/react'
import { cn } from '@Infowijs-eng/component-library/modules'

function MessageComposerTextInput({
  value, onChange, onSubmit, disabled,
}) {
  const { i18n } = useLingui()
  return (
    <textarea
      wrap="soft"
      rows="1"
      value={value}
      placeholder={!disabled ? i18n._('MessageComposerTextInput.placeholder') : null}
      className={cn(
        'w-full h-[32px] self-center bg-transparent border-0 focus:ring-0 flex-grow resize-none pl-2 pr-0 py-0.5',
        'leading-6',
        disabled && 'pointer-events-none',
      )}
      disabled={disabled}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => {
        if (e.code === 'Enter' && !e.shiftKey) {
          e.preventDefault()
          e.stopPropagation()
          if (value.length) {
            onSubmit(value)
            onChange('')
          }
        }
      }}
      onKeyUp={(e) => {
        if (e.target.scrollHeight < 152) {
          e.target.style.height = '32px'
          e.target.style.height = `${e.target.scrollHeight}px`
        }
      }}
    />
  )
}

MessageComposerTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

MessageComposerTextInput.defaultProps = {
  disabled: false,
}

export default MessageComposerTextInput
