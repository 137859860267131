import React from 'react'
import { NavigationItem } from '@Infowijs-eng/component-library/components'
import { To } from 'react-router-dom'

export interface MenuNavigationItemProps {
  label: string
  href?: string
  to?: To
  icon: React.ComponentType

}
export default function MenuNavigationItem({
  label, href = null, to = null, icon,
}: MenuNavigationItemProps) {
  return (
    <NavigationItem
      href={href}
      to={to}
      label={label}
      Icon={icon}
      className="h-14 md:h-fit"
      addonProps={{
        className: 'md:hidden lg:flex h-14 text-base',
        leadingAddonClassName: 'pl-6 md:pl-0 text-lg',
        wrapperClassName: 'space-x-0 gap-4 md:gap-1',
      }}
    />
  )
}
