import axios from 'axios'
import { useState } from 'react'
import { useLingui } from '@lingui/react'

// eslint-disable-next-line import/prefer-default-export
export default function useRequest(config = {}, baseUrl = '') {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const { i18n } = useLingui()
  const fetch = async (endpoint, additionalConfig) => {
    const requestUrl = `${baseUrl}${endpoint}`

    setIsLoading(true)
    setError(null)
    try {
      const response = await axios(
        requestUrl,
        {
          ...config,
          ...additionalConfig,
        },
      )

      if (response.status !== 200 && response.status !== 204) {
        const errorMessage = (response.data && response.data.errors)
                || [{ message: i18n.t('error.generic') }]
        setError(errorMessage)
      }
      setData(response.data && response.data.data ? response.data.data : response.data)
      setIsLoading(false)
    } catch (e) {
      setData(null)
      const errorMessage = (e.data && e.data.errors)
              || [{ message: i18n.t('error.generic') }]
      setError(errorMessage)
      setIsLoading(false)
    }
  }

  return [
    fetch,
    data,
    isLoading,
    error,
  ]
}
