import dayjs from 'dayjs'
import actionTypes from '../action-types'
import metaReducer from '../modules/metaReducer'
import restoreIncludableThumbnailCrop from '../modules/restoreIncludableThumbnailCrop'

export default (state = { data: {}, meta: {} }, action = {}) => {
  switch (action.type) {
    case actionTypes.NEWS.SET: {
      return {
        ...state,
        data: action.payload.reduce((acc, cur) => {
          acc[cur.id] = {
            id: cur.id,
            title: cur.title,
            createdAt: dayjs.unix(cur.createdAt).toISOString(),
            image: restoreIncludableThumbnailCrop(cur.imageUrl),
            excerpt: cur.excerpt,
            pinned: cur.pinned,
            group: cur.group,
            groupId: cur.groupId,
            source: cur.source,
          }
          return acc
        }, {}),
        meta: metaReducer(action.meta || {}, state.meta),
      }
    }
    default: {
      return state
    }
  }
}
