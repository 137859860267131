import React, { PropsWithChildren, ReactNode } from 'react'
import { Clickable, WithAddon } from '@Infowijs-eng/component-library/components'

export interface MenuItemProps {
  icon?: ReactNode
  onClick?: () => void
  href?: string
  target?: string
}

export default function MenuItem({
  children = null, icon = null, onClick = null, href = null, target = '_blank',
}: PropsWithChildren<MenuItemProps>) {
  return (
    <Clickable
      type="button"
      onClick={onClick}
      href={href}
      target={target}
      className={`text-black h-14 w-full flex items-center text-sm hover:bg-theme-50 hover:text-theme-600 p-6 md:p-4
                cursor-pointer focus:outline-none`}
    >
      <WithAddon
        fullWidth
        className="justify-start whitespace-normal"
        leadingAddon={icon}
        leadingAddonClassName="mr-4 w-5 h-5"
      >
        {children}
      </WithAddon>
    </Clickable>
  )
}
