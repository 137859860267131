import getFileDescription from './getFileDescription'

export default (type, message) => {
  switch (type) {
    case 1:
      return message
    case 2: {
      if (typeof message !== 'object') {
        return getFileDescription('error')
      }
      if (!message.name) {
        return getFileDescription()
      }

      const splitFileName = message.name.split('.')
      const fileExtension = splitFileName[splitFileName.length - 1]
      return getFileDescription(fileExtension)
    }

    case 3:
      return getFileDescription('image')

    default:
      return null
  }
}
