import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { getAccessToken } from '@Infowijs-eng/component-library/modules/authentication'
import { getHoyEndpoint } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import getApiHeaders from '../../modules/getApiHeaders'
import getCustomerProductName from '../../selectors/customerProduct/getCustomerProductName'
import setNews from '../../actions/news/setNews'

const onFetchNews = function* onFetchNews() {
  yield takeLatest(actionTypes.NEWS.FETCH, function* onFetchNewsSaga(action) {
    const accessToken = yield select(getAccessToken)
    const customerProductName = yield select(getCustomerProductName)
    const endpoint = getHoyEndpoint(customerProductName)
    const page = Number(action.payload) || 0

    let response
    try {
      response = yield call(axios, {
        url: `${endpoint}/hoy/v1/news?paged=1&page=${page}`,
        method: 'GET',
        headers: getApiHeaders({
          Authorization: `Bearer ${accessToken}`,
        }),
      })
    } catch (e) {
      rejectAction(action, response.data.errors)
      return
    }

    if (response.data.error) {
      rejectAction(action, [{ title: response.data.error }])
      return
    }

    if (response && response.data && response.data.data) {
      const paginationMeta = response.data.meta && response.data.meta.pagination
      if (paginationMeta) {
        paginationMeta.page += 1 // The old backend pagination works on 0 base instead of 1 base
      }
      yield put(setNews(response.data.data, response.data.meta && response.data.meta.pagination))
      acceptAction(action, response.data.data)
    }
  })
}

export default onFetchNews
