import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ArrowDownSmall, ArrowUpSmall,
} from '@Infowijs-eng/component-library/icons'
import { Avatar, Skeleton } from '@Infowijs-eng/component-library/components'
import { cn } from '@Infowijs-eng/component-library/modules'
import { TYPE_USER } from '@Infowijs-eng/component-library/constants/avatar'
import isUserLoading from '../../selectors/user/isUserLoading'
import getUser from '../../selectors/user/getUser'
import fetchUser from '../../actions/user/fetchUser'
import AccountMenuItems from './AccountMenuItems'

export default function DesktopAccountMenu() {
  const wrapperRef = useRef(null)
  const [isOpen, setOpen] = useState(false)

  const userLoading = useSelector(isUserLoading)
  const user = useSelector(getUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user?.avatar && !userLoading) {
      dispatch(fetchUser())
    }
  }, [user])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.stopPropagation()
        setOpen(false)
      }
    }

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  return (
    <div
      ref={wrapperRef}
      className="md:relative"
    >
      <div className="flex items-center">
        <button
          type="button"
          className="flex items-center"
          onClick={() => {
            setOpen(!isOpen)
          }}
        >
          <Avatar type={TYPE_USER} id={user?.id} alt="" />
          <div className="p-3">
            {userLoading && (
              <>
                <Skeleton className="w-[120px]" />
                <Skeleton className="w-[48px]" />
              </>
            )}
            {!userLoading && (
              <>
                <div className="w-fit text-base text-black font-bold whitespace-nowrap">{user?.fullName}</div>
                <div className="w-fit text-sm font-medium text-gray-500">{user?.subtitle}</div>
              </>
            )}
          </div>
          {isOpen ? <ArrowUpSmall /> : <ArrowDownSmall />}
        </button>
      </div>

      {
        isOpen && (
          <div
            className={cn(
              'absolute max-w-xs rounded-md shadow-lg bg-white ring-1 ring-black',
              'ring-opacity-5 focus:outline-none z-10 text-[1.25rem] origin-top-right right-0',
            )}
          >
            <AccountMenuItems />
          </div>
        )
      }
    </div>
  )
}
