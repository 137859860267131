import { Alert, Pagination } from '@Infowijs-eng/component-library/components'
import React from 'react'
import PropTypes from 'prop-types'
import { useLingui } from '@lingui/react'
import MessageGroup from './MessageGroup'
import ConversationListItem from './ConversationListItem'

function ConversationList({
  inboxId, conversations, meta, loading, errorMessages,
}) {
  const { i18n } = useLingui()
  return (
    <>
      <ul className="px-10 pt-3">
        {!!loading && !conversations?.length && (
          <MessageGroup loading />
        )}
        {!loading && !errorMessages?.length && !conversations?.length && (
          <Alert title={i18n._('messageGroups.messages.empty')} />
        )}
        {!loading && errorMessages && errorMessages.length && (
          <Alert
            errorMessages={
              errorMessages.map((error) => ({
                status: error.status,
                title: error.title || i18n._('error.generic'),
              }))
            }
          />
        )}
        {conversations?.map((conversationItem) => {
          const {
            recipient,
            conversationId,
          } = conversationItem

          return (
            <ConversationListItem
              key={recipient || conversationId}
              id={conversationId}
              inboxId={inboxId}
              recipient={recipient}
            />
          )
        })}
      </ul>
      <div className="flex justify-center py-8">
        {meta && meta.total > meta.limit && (
          <Pagination
            current={Math.floor(meta.offset / meta.limit) + 1}
            total={Math.floor(meta.total / meta.limit) + 1}
          />
        )}
      </div>
    </>
  )
}

ConversationList.propTypes = {
  inboxId: PropTypes.string.isRequired,
  conversations: PropTypes.arrayOf(PropTypes.shape({
    conversationId: PropTypes.string.isRequired,
    recipient: PropTypes.number,
  })),
  meta: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
  }),
  loading: PropTypes.bool,
  errorMessages: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.number,
    title: PropTypes.string,
  })),
}

ConversationList.defaultProps = {
  conversations: null,
  meta: null,
  loading: false,
  errorMessages: null,
}

export default ConversationList
