import {
  Avatar,
  Skeleton,
} from '@Infowijs-eng/component-library/components'
import PropTypes from 'prop-types'
import React from 'react'

function CustomerProductAvatar({ title, avatar }) {
  return (
    <div className="flex items-center text-left">
      <Avatar image={avatar} alt={title} />
      {!title ? <Skeleton className="mx-4" /> : <div className="px-4 text-base font-bold">{title}</div>}
    </div>
  )
}

CustomerProductAvatar.propTypes = {
  title: PropTypes.string,
  avatar: PropTypes.string,
}

CustomerProductAvatar.defaultProps = {
  title: null,
  avatar: null,
}

export default CustomerProductAvatar
