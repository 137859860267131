import { useStore } from 'react-redux'
import { getAccessToken } from '@Infowijs-eng/component-library/modules/authentication'
import getApiHeaders from '../modules/getApiHeaders'
import useRequest from './useRequest'

export default function useAuthenticatedRequest(configOptional = {}, baseUrl = '') {
  const [fetch, ...rest] = useRequest(configOptional, baseUrl)

  const store = useStore()

  function authenticatedFetch(endpoint, fetchConfig) {
    let config = (fetchConfig || {})
    const accessToken = getAccessToken(store.getState())

    config = {
      ...config,
      headers: {
        ...(config.headers || {}),
        ...getApiHeaders(
          {
            Authorization: `Bearer ${accessToken}`,
          },
        ),
      },
    }
    fetch(endpoint, config)
  }

  return [authenticatedFetch, ...rest]
}
