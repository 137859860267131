import { Trans } from '@lingui/macro'
import { Visibility, signOut } from '@Infowijs-eng/component-library/modules/authentication'
import getRandomSessionIdentifier
  from '@Infowijs-eng/component-library/modules/authentication/selectors/getRandomSessionIdentifier'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ArrowsLeftAndRight,
  ChatBubbleOutline,
  Lifebuoy,
  LockOpen,
} from '@Infowijs-eng/component-library/icons'
import { getHoyEndpoint } from '@Infowijs-eng/component-library/modules'
import MenuItem from './MenuItem'
import MenuDivider from './MenuDivider'
import MenuSectionHeader from './MenuSectionHeader'
import getCustomerProductName from '../../selectors/customerProduct/getCustomerProductName'

export default function AccountMenuItems() {
  const dispatch = useDispatch()
  const customerProductName = useSelector((state) => getCustomerProductName(state))
  const rsi = useSelector(getRandomSessionIdentifier)
  const adminRedirect = `${getHoyEndpoint(customerProductName)}/?rsi=${rsi}&teacher=false`

  const toggleFrontChat = () => {
    // @ts-expect-error expect frontchat on window
    window.FrontChat?.('show')
  }
  return (
    <>
      <div className="hidden md:block">
        <Visibility permissions="hoy.users.list">
          <MenuItem
            href={adminRedirect}
            target="_self"
            icon={<ArrowsLeftAndRight className="w-full h-full" />}
          >
            <Trans id="accountMenu.switchToAdmin">Switch to admin</Trans>
          </MenuItem>
        </Visibility>
        <MenuDivider />
      </div>
      <MenuSectionHeader>
        <Trans id="accountMenu.help">Help</Trans>
      </MenuSectionHeader>
      <MenuDivider />
      <MenuItem
        href="https://support.infowijs.nl/category/hoy"
        icon={<Lifebuoy className="w-full h-full" />}
      >
        <Trans id="supportMenu.supportCenter">Support Center</Trans>
      </MenuItem>
      <Visibility permissionsWithRegex="(infowijs.support.chat|clients.hoy_web)">

        <MenuItem onClick={toggleFrontChat} icon={<ChatBubbleOutline className="w-full h-full" />}>
          <Trans id="supportMenu.chat">Support Chat</Trans>
        </MenuItem>
      </Visibility>
      <MenuSectionHeader>
        <Trans id="accountMenu.account">Account</Trans>
      </MenuSectionHeader>
      <MenuDivider />
      <MenuItem onClick={() => dispatch(signOut(rsi, true))} icon={<LockOpen className="w-full h-full" />}>
        <Trans id="userProfileMenu.signout">Sign out</Trans>
      </MenuItem>
    </>
  )
}
