import React, {
  useEffect,
  useRef,
} from 'react'

import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

function PageContent({ children }) {
  const location = useLocation()
  const scrollWindowRef = useRef()
  useEffect(() => {
    if (!scrollWindowRef || !scrollWindowRef.current) {
      return
    }
    scrollWindowRef.current.scrollTo(0, 0)
  }, [location, scrollWindowRef])

  return (
    <div ref={scrollWindowRef} className="z-0 min-h-full w-full overflow-y-auto lg:(h-full w-8/12) xl:w-2/3 2xl:w-full">
      {children}
    </div>
  )
}

PageContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
  ]),
}

PageContent.defaultProps = {
  children: null,
}

export default PageContent
