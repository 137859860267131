import React, {
  useEffect,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Avatar, Skeleton } from '@Infowijs-eng/component-library/components'
import { TYPE_USER } from '@Infowijs-eng/component-library/constants/avatar'
import isUserLoading from '../../selectors/user/isUserLoading'
import getUser from '../../selectors/user/getUser'
import fetchUser from '../../actions/user/fetchUser'
import AccountMenuItems from './AccountMenuItems'

export default function MobileAccountMenu() {
  const userLoading = useSelector(isUserLoading)
  const user = useSelector(getUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user?.avatar && !userLoading) {
      dispatch(fetchUser())
    }
  }, [user])

  return (
    <div className="bg-gray-50 flex-grow">
      <div
        className="flex items-center ml-4 mt-8 md:mt-0"
      >
        <Avatar type={TYPE_USER} id={user?.id} alt="" />
        <div className="ml-2">
          {userLoading && (
            <>
              <Skeleton className="w-[120px]" />
              <Skeleton className="w-[48px]" />
            </>
          )}

          {!userLoading && (
            <>
              <div className="text-base text-black font-bold whitespace-nowrap">{user?.fullName}</div>
              <div className="w-fit text-sm font-medium text-gray-500">{user?.subtitle}</div>
            </>
          )}
        </div>
      </div>

      <AccountMenuItems />
    </div>
  )
}
