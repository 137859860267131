import {
  useEffect,
  useState,
} from 'react'

export default function useWindowInFocus(): boolean {
  const [inFocus, setInFocus] = useState(document.hasFocus())

  useEffect(() => {
    const onFocus = () => setInFocus(true)
    const onBlur = () => setInFocus(false)

    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  return inFocus
}
