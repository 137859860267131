import { MessageType } from '@Infowijs-eng/hoy_shared/modules/messages/types'

export default (fileExtension) => {
  switch (fileExtension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'svg':
      return MessageType.Image

      // TODO: Add when video is actually a supported message type
      // case 'mp4':
      // case 'mov':
      // case 'avi':
      // case 'gif':
      //   return type.VIDEO

    default:
      return MessageType.File
  }
}
