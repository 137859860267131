import createSagaMiddleware from 'redux-saga'
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import {
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { persistedReducers as authPersistedReducers } from '@Infowijs-eng/component-library/modules/authentication'
import {
  conversations,
  inboxes,
} from '@Infowijs-eng/hoy_shared/modules/messages/reducers'
import reducers from './reducers'
import sagas from './sagas'

const persistConfig = {
  key: 'infowijs-hoy-web',
  storage,
  whitelist: ['customerProduct'],
}

function createPersistedStore() {
  const sagaMiddleware = createSagaMiddleware()

  const persistedReducers = persistReducer(
    persistConfig,
    combineReducers({
      ...reducers,
      ...authPersistedReducers,
      inboxes,
      conversations,
    }),
  )

  const store = configureStore({
    reducer: persistedReducers,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: () => [sagaMiddleware],
  })

  const persistor = persistStore(store)

  sagaMiddleware.run(sagas)

  return {
    store,
    persistor,
  }
}

export {
  /* eslint-disable-next-line import/prefer-default-export */
  createPersistedStore,
}
