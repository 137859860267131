import React from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  FloatingAddon,
  IconContainer,
  Skeleton,
} from '@Infowijs-eng/component-library/components'
import { Megaphone, People } from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'
import { useLingui } from '@lingui/react'
import { TYPE_CUSTOMER_PRODUCT, TYPE_MESSAGE_GROUP, TYPE_USER } from '@Infowijs-eng/component-library/constants/avatar'
import getLastMessageFormat from '../../modules/getLastMessageFormat'
import getRelativeTime from '../../modules/getRelativeTime'
import getMultiRecipientAvatarBgColor from '../../modules/getMultiRecipientAvatarBgColor'

function MessageGroup({
  type,
  avatarType,
  avatarId,
  userCount,
  isRead,
  hasReplied,
  title,
  subtitle,
  lastMessageContent,
  lastMessageType,
  updatedAt,
  isActive,
  loading,
}) {
  const { i18n } = useLingui()
  let avatarConversationType
  switch (type) {
    case 2:
      avatarConversationType = <IconContainer><Megaphone /></IconContainer>
      break
    case 3:
      avatarConversationType = <IconContainer><People /></IconContainer>
      break
    default:
      avatarConversationType = undefined
  }

  return (
    <div
      className={cn(
        'w-full flex items-center py-4 relative space-x-4',
        'after:content-[""] after:absolute after:top-0 after:left-[-16px] after:box-content after:w-full after:h-full',
        'after:rounded-[16px] after:px-[16px] after:z-[-1] hover:after:bg-gray-50 active:after:bg-gray-100',
        isActive && 'after:bg-gray-50',
      )}
    >
      <FloatingAddon addon={avatarConversationType} className="shrink-0">
        <Avatar
          id={avatarId}
          count={userCount}
          type={avatarType}
          className={userCount && userCount > 0 ? getMultiRecipientAvatarBgColor(userCount) : undefined}
        />
      </FloatingAddon>
      <div className="w-full space-y-1">
        <div className="flex flex-row justify-between items-start">
          <div className="font-extrabold font-display leading-tight line-clamp-2" title={title}>
            {(loading || !title) && <Skeleton className="w-24" />}
            {title}
          </div>
          <div className="text-xs text-gray-500 font-sans font-bold whitespace-nowrap pt-0.5">
            {!updatedAt && <Skeleton sm />}
            {updatedAt && getRelativeTime(updatedAt, i18n)}
          </div>
        </div>
        {loading && <Skeleton sm className="w-16" />}
        {subtitle && <div className="line-clamp-1 leading-tight mb-3 text-sm font-bold">{subtitle}</div>}
        <div className="flex flex-row justify-between items-center">
          <div
            className="text-gray-900 text-sm font-medium line-clamp-2 break-all leading-normal"
            title={getLastMessageFormat(lastMessageType, lastMessageContent)}
          >
            {!hasReplied && <div className={cn('mr-1', 'inline-block', 'font-sans')}>↩︎</div>}
            {getLastMessageFormat(lastMessageType, lastMessageContent)}
          </div>
          {!isRead && <div className="inline-block bg-theme-500 rounded-full h-3 w-3 flex-shrink-0" />}
        </div>
      </div>
    </div>
  )
}

MessageGroup.propTypes = {
  type: PropTypes.number,
  avatarId: PropTypes.string,
  avatarType: PropTypes.oneOf([TYPE_USER, TYPE_MESSAGE_GROUP, TYPE_CUSTOMER_PRODUCT]),
  userCount: PropTypes.number,
  isRead: PropTypes.bool,
  hasReplied: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  lastMessageContent: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  lastMessageType: PropTypes.number,
  updatedAt: PropTypes.string,
  isActive: PropTypes.bool,
  loading: PropTypes.bool,
}

MessageGroup.defaultProps = {
  type: null,
  avatarId: null,
  avatarType: null,
  userCount: null,
  isRead: true,
  title: null,
  hasReplied: true,
  lastMessageContent: '',
  lastMessageType: 1,
  subtitle: null,
  updatedAt: null,
  isActive: false,
  loading: false,
}

export default MessageGroup
