import {
  useEffect,
  useState,
} from 'react'
import CryptoJS from 'crypto-js'
import { useSelector } from 'react-redux'
import decodeJWT from '@Infowijs-eng/component-library/modules/authentication/modules/decodeJWT'
import { getAccessToken } from '@Infowijs-eng/component-library/modules/authentication'
import getUser from '../selectors/user/getUser'
import getCustomerProduct from '../selectors/customerProduct/getCustomerProduct'

const defaultChatId = '582a2d96adb9b872f7925087983c886c'
const defaultFrontSecret = '3767e0f0cc1ab66dfa579746f7cc9f12'

export default (chatId = defaultChatId, frontSecret = defaultFrontSecret) => {
  const user = useSelector(getUser)
  const customerProduct = useSelector(getCustomerProduct)
  const [frontLoaded, setFrontLoaded] = useState(false)

  const accessToken = useSelector(getAccessToken)
  const jwtPayload = decodeJWT(accessToken)
  const {
    user: jwtUser, customer, permissions,
  } = jwtPayload

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js'
    document.body.appendChild(script)

    script.onload = () => {
      window.FrontChat('init', {
        chatId,
        useDefaultLauncher: false,
      })

      // eslint-disable-next-line camelcase
      window.FrontChat('onUnreadChange', ({ unread_count }) => {
        // eslint-disable-next-line camelcase
        if (unread_count > 0) {
          window.FrontChat('show')
        }
      })

      setFrontLoaded(true)
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (!frontLoaded || (!user && !user.email)) {
      return
    }

    const hash = CryptoJS.HmacSHA256(user.email, frontSecret)
    const userHash = hash.toString(CryptoJS.enc.Hex)

    window.FrontChat('identity', {
      email: user.email,
      userHash,
      name: `${user.firstName} ${user.lastName}`,
      customFields: {
        permissions: permissions.join(', '),
        customerName: customer.name,
        customerId: customer.id,
        customerProductName: customerProduct.name,
        customerProductId: customerProduct.id,
        userId: jwtUser.uuid || jwtUser.id,
        userIdDeprecated: jwtUser.id,
        goToUser: `https://admin.infowijs.nl/customers/${customer.id}/users/${jwtUser.uuid}`,
        goToCustomerProduct: `https://admin.infowijs.nl/customers/products/${customerProduct.id}`,
      },
    })
  }, [user, frontLoaded])
}
