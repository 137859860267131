import dayjs from 'dayjs'
import React, {
  useEffect, useMemo,
  useRef,
  useState,
} from 'react'
import {
  useParams,
} from 'react-router-dom'
import { Button } from '@Infowijs-eng/component-library/components'
import { useDispatch, useSelector } from 'react-redux'
import { useLingui } from '@lingui/react'
import { Trans } from '@lingui/macro'
import {
  cn,
  useDefaultPageLimit,
} from '@Infowijs-eng/component-library/modules'
import {
  getConversation, getConversationOwner, makeGetDisabledMessage,
  makeGetMessages,
} from '@Infowijs-eng/hoy_shared/modules/messages/selectors'
import {
  getConversationSendMessageRequestStatus,
  getFetchConversationMetaRequestStatus,
} from '@Infowijs-eng/hoy_shared/modules/messages/selectors/status'
import {
  fetchConversationMessages,
  fetchConversationMeta,
  sendMessage,
} from '@Infowijs-eng/hoy_shared/modules/messages/actions'
import sendReadMessage from '@Infowijs-eng/hoy_shared/modules/messages/actions/sendReadMessage'
import { ConversationType, MessageType } from '@Infowijs-eng/hoy_shared/modules/messages/types'
import { useConversationAutoRefresh } from '@Infowijs-eng/hoy_shared/modules/messages/hooks'
import getFullName from '@Infowijs-eng/hoy_shared/modules/getFullName'
import getDisabledChatHintTranslation from '../../modules/getDisabledChatHintTranslation.ts'
import getUserId from '../../sagas/user/getUserId.ts'
import useWindowInFocus from '../../hooks/useWindowInFocus.ts'
import Message from '../../components/messages/Message'
import MessageDetailBar from '../../components/messages/MessageDetailBar'
import MessageComposer from '../../components/messages/MessageComposer'
import StatusMessage from '../../components/messages/StatusMessage'

function MessagesDetailScreen() {
  const getMessages = makeGetMessages()
  const getDisabledMessage = useMemo(makeGetDisabledMessage, [])

  const dispatch = useDispatch()
  const { conversationId, recipient } = useParams()
  const lastMessageRef = useRef(null)
  const defaultPageLimits = [30, 90]
  const defaultPageLimit = useDefaultPageLimit(defaultPageLimits)
  const [pageLimit, setPageLimit] = useState(defaultPageLimit)

  const { i18n } = useLingui()

  const userId = useSelector(getUserId)

  const sendMessageRequestStatus = useSelector((state) => getConversationSendMessageRequestStatus(state, {
    inboxId: userId,
    conversationId,
  }))

  const messages = useSelector((state) => getMessages(state, {
    inboxId: userId,
    conversationId,
    recipient,
  }))

  const conversationIsLoading = useSelector((state) => getFetchConversationMetaRequestStatus(state, {
    inboxId: userId,
    conversationId,
  })).isLoading
  const conversation = useSelector((state) => getConversation(state, {
    inboxId: userId,
    conversationId,
  }))

  const disabledMessageType = useSelector((state) => getDisabledMessage(state, {
    inboxId: userId,
    conversationId,
    userId,
  }))

  const conversationOwner = useSelector((state) => getConversationOwner(state, {
    inboxId: userId,
    conversationId,
  }))

  let lastMessageId
  if (messages.length) {
    lastMessageId = messages[messages.length - 1].id
  }

  useEffect(
    () => {
      const loadingMoreMessages = defaultPageLimit < pageLimit

      let timeout
      if (!loadingMoreMessages && lastMessageRef.current) {
        timeout = setTimeout(() => {
          lastMessageRef.current.scrollIntoView()
        }, 100)
      }
      return () => {
        if (timeout) clearTimeout(timeout)
      }
    },
    [lastMessageId, lastMessageRef],
  )

  useEffect(() => {
    dispatch(fetchConversationMeta({
      inboxId: userId,
      conversationId,
    }))
    dispatch(fetchConversationMessages({
      inboxId: userId,
      conversationId,
    }))
  }, [conversationId])

  useEffect(() => {
    if (messages
      && !!messages.length
      && conversation
      && !conversation.isRead
      && conversationId
      && recipient !== 'all'
    ) {
      dispatch(sendReadMessage({
        inboxId: userId,
        conversationId,
        recipient: parseInt(recipient, 10),
        type: MessageType.Read,
        content: dayjs().unix(),
      }))
    }
  }, [messages, conversation, conversationId, recipient])

  const inFocus = useWindowInFocus()
  useConversationAutoRefresh(userId, conversationId, inFocus)

  const disabledChatHint = getDisabledChatHintTranslation(disabledMessageType, conversationOwner)

  if (!conversation && !messages && !conversationIsLoading) {
    return (
      <div className="min-h-full w-full flex flex-col">
        <MessageDetailBar inboxId={userId} />
        <div className={cn(
          'flex-grow flex flex-col pt-2 px-6 sm:(pt-4 pl-8 pr-10) items-center align-middle justify-center bg-gray-50',
        )}
        >
          <div>{i18n._('messages.error.default')}</div>
          <div>{i18n._('messages.error.support')}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-full w-full flex flex-col">
      <MessageDetailBar inboxId={userId} />

      <div className="flex-grow flex flex-col pt-2 px-6 sm:(pt-4 pl-8 pr-10) bg-gray-50">
        {messages && messages.length >= pageLimit && (
          <div className="flex-grow flex items-center justify-center space-x-2 py-4">
            <Button small onClick={() => setPageLimit(pageLimit + defaultPageLimit)}>
              <Trans id="messageDetailScreen.more">More</Trans>
            </Button>
          </div>
        )}

        {messages && messages
          .slice(
            pageLimit > messages.length ? 0 : messages.length - pageLimit,
            messages.length,
          )
          .map(
            (message) => {
              if (message.type < 10) {
                const member = conversation.type === ConversationType.Multicast
                  && !message.sentByMe
                  && conversation.members.find((messageGroupUser) => (
                    messageGroupUser.idDeprecated === message.createdBy
                  ))
                return (
                  <Message
                    key={message.id}
                    type={message.type}
                    content={message.content}
                    createdAt={message.createdAt}
                    userTitle={(member && (member.title || getFullName(member))) || null}
                    avatar={(member && member.avatar) || null}
                    sentByMe={message.sentByMe}
                    showAuthor={message.showAuthor}
                    showDate={message.showDate}
                    showTime={message.showTime}
                  />
                )
              }

              return <StatusMessage key={message.id} type={message.type} content={message.content} />
            },
          )}
        <div ref={lastMessageRef} />
        {!messages && (
          <div>
            <Message />
            <Message sentByMe />
          </div>
        )}
      </div>

      {conversation && (
        <div className="sticky bottom-0 w-screen lg:w-full bg-white">
          {disabledChatHint && (
            <div className="text-center text-sm p-6">
              {disabledChatHint}
            </div>
          )}
          {!disabledChatHint && (
            <MessageComposer
              onSend={(message) => dispatch(sendMessage({
                ...message,
                inboxId: userId,
                conversationId,
                recipient,
              }))}
              isSending={sendMessageRequestStatus.isLoading}
              conversationId={conversationId}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default MessagesDetailScreen
