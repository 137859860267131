import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import { i18n } from '@lingui/core'
import getNews from './getNews'

const isThisWeek = (timestamp) => dayjs().isSame(timestamp, 'week')
const isLastWeek = (timestamp) => {
  const today = dayjs()
  const lastWeekStart = today.subtract(1, 'week').startOf('week')
  const lastWeekEnd = today.subtract(1, 'week').endOf('week')
  return dayjs(timestamp).isAfter(lastWeekStart) && dayjs(timestamp).isBefore(lastWeekEnd)
}
const isThisYear = (timestamp) => dayjs().isSame(timestamp, 'year')

const capitalizeDayJsString = (str) => str.charAt(0).toUpperCase() + str.slice(1)
const getTimeframe = (item) => {
  switch (true) {
    case item.pinned: return i18n._('news.highlighted')
    case isThisWeek(item.createdAt): return i18n._('news.thisWeek')
    case isLastWeek(item.createdAt): return i18n._('news.lastWeek')
    case isThisYear(item.createdAt): return capitalizeDayJsString(dayjs(item.createdAt).format('MMMM'))
    default:
      return capitalizeDayJsString((dayjs(item.createdAt).format('MMMM YYYY')))
  }
}

export default createSelector(
  getNews,
  (items) => items.reduce((acc, item) => {
    const timeframe = getTimeframe(item)
    acc[timeframe] = [
      ...(acc[timeframe] ?? []),
      item,
    ]
    return acc
  }, {}),
)
