import React from 'react'

import PropTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { MessageType } from '@Infowijs-eng/hoy_shared/modules/messages/types'

function StatusMessage({
  type,
  content,
}) {
  return content && (
    type === MessageType.UserAdded
  || type === MessageType.UserRemoved
  || type === MessageType.UserLeft
  ) && (
  <div className="self-center text-sm text-gray-700 py-2 sm:py-4">
    {type === MessageType.UserAdded && (
      <Trans
        id="StatusMessage.userAdded"
        values={{ name: content.name }}
      />
    )}
    {type === MessageType.UserRemoved && (
      <Trans
        id="StatusMessage.userRemoved"
        values={{ name: content.name }}
      />
    )}
    {type === MessageType.UserLeft && (
      <Trans
        id="StatusMessage.userLeft"
        values={{ name: content.name }}
      />
    )}
  </div>
  )
}

StatusMessage.propTypes = {
  type: PropTypes.number,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ]),
}

StatusMessage.defaultProps = {
  type: 1,
  content: null,
}

export default StatusMessage
