import React, {
  useEffect,
  useState,
} from 'react'
import {
  Alert,
  Badge,
  Clickable,
  Heading,
  NewsListItemSocial,
  NewsListItemSocialSkeleton,
  NewsListItemText,
  NewsListItemTextSkeleton,
  Pagination,
  Skeleton,
} from '@Infowijs-eng/component-library/components'
import { Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { useLingui } from '@lingui/react'
import {
  cn,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import { useLocation } from 'react-router-dom'
import Header from '../../components/Header'
import newsPlaceholder from '../../images/news-placeholder.svg'
import usePageParam from '../../hooks/usePageParam'
import getRelativeTime from '../../modules/getRelativeTime'
import getNewsMeta from '../../selectors/news/getNewsMeta'
import fetchNews from '../../actions/news/fetchNews'
import getNewsByTimeframe from '../../selectors/news/getNewsByTimeframe'

export default function NewsOverviewScreen() {
  const promisifyDispatch = usePromisifyDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const newsItemsByTimeframe = useSelector(getNewsByTimeframe)
  const { i18n } = useLingui()

  const meta = useSelector(getNewsMeta)
  const pageParam = usePageParam(-1)
  const {
    pathname,
    search,
  } = useLocation()

  useEffect(() => {
    setLoading(true)
    const promise = promisifyDispatch(fetchNews(pageParam))
    promise
      .catch((e) => setErrorMessages(e))
      .finally(() => {
        setLoading(false)
        setErrorMessages(false)
      })

    return () => {
      promise.cancel()
    }
  }, [pageParam])

  return (
    <div>
      <Header>
        <Trans id="news.title">News</Trans>
      </Header>

      <div className="px-10 py-3 space-y-4">
        {!loading && errorMessages && (
        <div>
          <Alert errorMessages={errorMessages} />
        </div>
        )}
        {!errorMessages && !loading && (!newsItemsByTimeframe || !Object.keys(newsItemsByTimeframe).length) && (
        <div>
          <Alert title="Geen nieuws gevonden" />
        </div>
        )}
        {loading && ((!newsItemsByTimeframe || !Object.keys(newsItemsByTimeframe).length)) && (
        <div className="space-y-4">
          <Skeleton className="h-8 w-[100px]" />
          <NewsListItemTextSkeleton featured />
          <div className="flex space-x-4">
            <NewsListItemSocialSkeleton />
            <NewsListItemSocialSkeleton />
          </div>
          <NewsListItemTextSkeleton />
          <NewsListItemTextSkeleton />
        </div>
        )}
        {Object.entries(newsItemsByTimeframe)
          .map((entry, newsTimeframeIndex) => {
            const key = entry[0]
            const newsItemTimeframe = entry[1]

            return (
              <div className={cn(
                'grid grid-cols-6 gap-4 relative',
                !!newsItemTimeframe.every((newsItem) => !!newsItem.pinned) && [
                  'mt-4 mb-8 after:content-[""] after:absolute after:top-[-20px] after:left-[-20px] after:box-content',
                  'after:w-full after:h-full after:rounded-[20px] after:p-[20px] after:z-[-1] after:bg-gray-50',
                ],
              )}
              >
                <Heading fontSize={Heading.size.H5} className="whitespace-nowrap col-span-6">
                  {key}
                </Heading>
                {newsItemTimeframe.map((newsItem, newsItemIndex) => {
                  const isActive = pathname.endsWith(newsItem.id)
                  switch (newsItem.source) {
                    case 'instagram':
                      return (
                        <Clickable
                          key={newsItem.id}
                          className="col-span-3 text-left"
                          to={{
                            pathname: `/news/${newsItem.id}`,
                            search,
                          }}
                        >
                          <NewsListItemSocial
                            source={newsItem.source}
                            excerpt={newsItem.excerpt}
                            image={newsItem.image || newsPlaceholder}
                            badge={newsItem.group && <Badge className="bg-theme-500">{newsItem.group}</Badge>}
                            timestamp={getRelativeTime(newsItem.createdAt, i18n)}
                            active={isActive}
                          />
                        </Clickable>
                      )
                    default: {
                      return (
                        <Clickable
                          key={newsItem.id}
                          className="col-span-6 text-left"
                          to={{
                            pathname: `/news/${newsItem.id}`,
                            search,
                          }}
                        >
                          <NewsListItemText
                            title={newsItem.title}
                            image={newsItem.image || newsPlaceholder}
                            badge={newsItem.group && (<Badge className="bg-theme-500">{newsItem.group}</Badge>)}
                            timestamp={getRelativeTime(newsItem.createdAt, i18n)}
                            featured={((newsItemIndex === 0 && newsTimeframeIndex === 0) || newsItem.pinned)}
                            active={isActive}
                          />
                        </Clickable>
                      )
                    }
                  }
                })}
              </div>
            )
          })}
      </div>

      {meta && meta.pages > 0 && (
      <div className="flex justify-center py-8">
        <Pagination
          current={meta.page}
          total={meta.pages}
        />
      </div>
      )}
    </div>
  )
}
