import { useSelector } from 'react-redux'
import { getHoyEndpoint } from '@Infowijs-eng/component-library/modules'
import getCustomerProductName from '../selectors/customerProduct/getCustomerProductName'
import useAuthenticatedRequest from './useAuthenticatedRequest'

// eslint-disable-next-line import/prefer-default-export
export default function useIncludableRequest(config) {
  const customerProductName = useSelector(getCustomerProductName)
  const baseUrl = getHoyEndpoint(customerProductName)

  return useAuthenticatedRequest(config, baseUrl)
}
