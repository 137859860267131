import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Clickable } from '@Infowijs-eng/component-library/components'
import {
  useLocation,
  useMatch,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  getConversation,
  makeGetVirtualConversation,
} from '@Infowijs-eng/hoy_shared/modules/messages/selectors'
import isInteger from '@Infowijs-eng/hoy_shared/modules/isInteger'
import { ConversationType } from '@Infowijs-eng/hoy_shared/modules/messages/types'
import getCustomerProductName from '../../selectors/customerProduct/getCustomerProductName'
import MessageGroup from './MessageGroup'

function ConversationListItem({
  id,
  inboxId,
  recipient,
}) {
  const getVirtualConversation = useMemo(() => makeGetVirtualConversation(), [])
  const customerProductName = useSelector(getCustomerProductName)
  const mainConversation = useSelector((state) => getConversation(state, {
    inboxId,
    conversationId: id,
  }))
  const virtualConversation = useSelector((state) => getVirtualConversation(state, {
    inboxId,
    conversationId: id,
    recipient,
  }))

  const conversation = isInteger(recipient) ? virtualConversation : mainConversation
  const { search } = useLocation()
  const inArchive = !!useMatch('/messages/archive/*')

  if (!customerProductName || !conversation) {
    return null
  }

  let path

  if (isInteger(recipient)) {
    path = `/messages/${inArchive ? 'archive/' : ''}bcc/${id}/${recipient}`
  } else {
    path = conversation.type !== ConversationType.Multicast
      && conversation.numUsers > 2
      ? `/messages/${inArchive ? 'archive/' : ''}bcc/${id}`
      : `/messages/${inArchive ? 'archive/' : ''}${id}`
  }

  const isActive = !!useMatch(path)
  const lastMessage = conversation.lastMessage || null
  const hasReplied = lastMessage && !lastMessage.sentByMe

  let avatarType = conversation.avatars?.[0]?.type ?? undefined
  let avatarId = conversation.avatars?.[0]?.id ?? undefined

  let groupMemberCount
  if (conversation.conversationType === ConversationType.Multicast) {
    groupMemberCount = conversation.numUsers
  } else if (conversation.numUsers > 2) {
    // Conversations other than multicast should exclude own user when there are more than 2 users in messagegroup
    groupMemberCount = conversation.numUsers - 1
    avatarType = undefined
    avatarId = undefined
  }

  return (
    <li>
      <Clickable
        to={{ pathname: path, search }}
        className="border-b border-gray-50 block"
      >
        <MessageGroup
          type={conversation.type}
          avatarId={avatarId}
          avatarType={avatarType}
          userCount={groupMemberCount}
          isRead={conversation.isRead}
          hasReplied={hasReplied}
          title={conversation.title}
          subtitle={conversation.subtitle}
          lastMessageContent={lastMessage?.content}
          lastMessageType={lastMessage?.type}
          updatedAt={lastMessage?.createdAt}
          isActive={isActive}
        />
      </Clickable>
    </li>
  )
}

ConversationListItem.propTypes = {
  id: PropTypes.string.isRequired,
  inboxId: PropTypes.string.isRequired,
  recipient: PropTypes.number,
}

ConversationListItem.defaultProps = {
  recipient: null,
}

export default ConversationListItem
