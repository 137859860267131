import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import {
  PaperPlane,
  Paperclip,
} from '@Infowijs-eng/component-library/icons'
import { Button } from '@Infowijs-eng/component-library/components'
import { MessageType } from '@Infowijs-eng/hoy_shared/modules/messages/types'
import { cn } from '@Infowijs-eng/component-library/modules'
import getFileExtension from '../../modules/getFileExtension'
import getFileType from '../../modules/getFileType'
import MessageComposerFilePreview from './MessageComposerFilePreview'
import FileInputButton from './FileInputButton'
import MessageComposerTextInput from './MessageComposerTextInput'
import useFileUpload from '../../hooks/useFileUpload'

function MessageComposer({
  disabled, onSend, isSending, conversationId,
}) {
  const fileUpload = useFileUpload()

  const [text, setText] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadingFileFailed, setUploadingFileFailed] = useState(null)

  const textInputDisabled = disabled || !!selectedFile
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(!text.length)
  const sendButtonDisabled = disabled || isSendButtonDisabled

  useEffect(() => {
    if (!isSending) {
      setText('')
      setSelectedFile(null)
      setIsSendButtonDisabled(false)
    }
  }, [isSending, conversationId])

  return (
    <div className="relative w-full flex pt-4 pb-6 px-6 lg:(pl-8 pr-10 space-x-2)">
      <FileInputButton
        neutral
        leadingAddon={<Paperclip />}
        onSelectFile={(file) => {
          setUploadProgress(0)
          setUploadingFileFailed(null)
          setIsSendButtonDisabled(true)
          setSelectedFile({ name: file.name, original: file })
          fileUpload(
            file,
            // upload progress is divided by 99 and will only complete to 100% on then()
            // because file upload api takes quite a bit of time to resolve and return the url
            (e) => setUploadProgress(Math.ceil((e.loaded / e.total) * 99)),
          )
            .then((res) => {
              setSelectedFile((prev) => ({ ...prev, url: res.data.data.url }))
              setUploadProgress(100)
              setIsSendButtonDisabled(false)
            })
            .catch((err) => setUploadingFileFailed(err.message))
        }}
      />
      <div
        className={cn(
          'relative min-w-0 flex-grow flex flex-row p-1 bg-gray-50 border border-gray-50 justify-between',
          'rounded-[1.5rem]',
          !textInputDisabled && 'border-gray-100 bg-white',
        )}
      >
        {!selectedFile && (
          <MessageComposerTextInput
            value={text}
            onChange={(value) => {
              setIsSendButtonDisabled(!value.length)
              setText(value)
            }}
            onSubmit={(value) => onSend({
              type: MessageType.Text,
              content: value,
            })}
          />
        )}
        {selectedFile && (
          <MessageComposerFilePreview
            file={selectedFile}
            progress={uploadProgress}
            uploadErrorMessage={uploadingFileFailed}
            onRetry={() => {
              setUploadProgress(0)
              setUploadingFileFailed(null)
              setIsSendButtonDisabled(true)
              fileUpload(
                selectedFile.original,
                (e) => setUploadProgress(Math.ceil((e.loaded / e.total) * 99)),
              )
                .then((res) => {
                  setSelectedFile((prev) => ({ ...prev, url: res.data.data.url }))
                  setUploadProgress(100)
                  setIsSendButtonDisabled(false)
                })
                .catch((err) => setUploadingFileFailed(err.message))
            }}
            onCancel={() => setSelectedFile(null)}
          />
        )}
        <Button
          type="submit"
          small
          primary
          leadingAddon={<PaperPlane />}
          disabled={sendButtonDisabled}
          onClick={() => {
            setIsSendButtonDisabled(true)
            if (selectedFile && selectedFile.url) {
              const fileExtension = getFileExtension(selectedFile.name)
              const fileType = getFileType(fileExtension)
              let type = MessageType.File
              let content = { name: selectedFile.name, url: selectedFile.url }
              if (fileType === type.IMAGE) {
                type = MessageType.Image
                content = content.url
              }
              onSend({ type, content })
              return
            }
            onSend({
              type: MessageType.Text,
              content: text,
            })
          }}
          className={[
            'self-end',
            (sendButtonDisabled) && 'bg-gray-200',
          ]}
        />
      </div>
      <div className="absolute bottom-1 right-4">
        {!!text.length && (
          <span className="text-xs leading-none whitespace-nowrap truncate">
            <strong><Trans id="MessageComposer.helper.enter" /></strong>
            {' '}
            <Trans id="MessageComposer.helper.enterText" />
            {', '}
            <strong><Trans id="MessageComposer.helper.shiftEnter" /></strong>
            {' '}
            <Trans id="MessageComposer.helper.shiftEnterText" />
          </span>
        )}
      </div>
    </div>
  )
}

MessageComposer.propTypes = {
  onSend: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isSending: PropTypes.bool,
  conversationId: PropTypes.string.isRequired,
}

MessageComposer.defaultProps = {
  disabled: false,
  isSending: false,
}

export default MessageComposer
