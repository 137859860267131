import authActionTypes from '@Infowijs-eng/component-library/modules/authentication/actionTypes'
import decodeJWT from '@Infowijs-eng/component-library/modules/authentication/modules/decodeJWT'
import actionTypes from '../action-types'

const initialData = {
  title: null,
  avatar: null,
  flags: null,
  design: null,
}

export default (state = initialData, action = {}) => {
  switch (action.type) {
    case actionTypes.USER.SET: {
      return {
        ...state,
        title: action.payload.community.title,
        avatar: action.payload.community.avatar,
        flags: action.payload.flags,
      }
    }
    case actionTypes.CUSTOMER_PRODUCT.SET_DESIGN: {
      return {
        ...state,
        design: action.payload,
      }
    }
    case authActionTypes.SET_ACCESS_TOKEN: {
      return {
        ...state,
        ...decodeJWT(action.payload).customerProduct,
      }
    }
    default:
      return state
  }
}
