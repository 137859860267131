import React from 'react'
import PropTypes from 'prop-types'

function Placeholder({
  image, alt, title, children,
}) {
  return (
    <div className="text-gray-500 font-bold text-sm leading-snug flex flex-col items-center justify-center">
      {image && <img src={image} alt={alt} width="60%" className="w-44 h-44" />}
      {title && (
        <h1 className="mt-4 text-h3 font-display font-extrabold leading-normal text-gray-600">
          {title}
        </h1>
      )}
      {children}
    </div>
  )
}

Placeholder.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  alt: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

Placeholder.defaultProps = {
  image: null,
  alt: '',
  title: null,
}

export default Placeholder
