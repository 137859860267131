import { useSelector } from 'react-redux'
import {
  Visibility,
  getAccessToken,
  getRandomSessionIdentifier,
} from '@Infowijs-eng/component-library/modules/authentication'
import { useLingui } from '@lingui/react'
import { ClipboardCheck, Home, Mailbox } from '@Infowijs-eng/component-library/icons'
import { getHoyEndpoint } from '@Infowijs-eng/component-library/modules'
import React from 'react'
import MenuNavigationItem from './menu/MenuNavigationItem'
import MenuDivider from './menu/MenuDivider'
import Navigation from './Navigation'
import getCustomerProductName from '../selectors/customerProduct/getCustomerProductName'
import NavigationBarSkeleton from './NavigationBarSkeleton'

export default function NavigationBar() {
  const customerProductName = useSelector(getCustomerProductName)
  const rsi = useSelector(getRandomSessionIdentifier)
  const accessToken = useSelector(getAccessToken)

  const { i18n } = useLingui()

  if (!customerProductName || !rsi || !accessToken) {
    return <NavigationBarSkeleton />
  }

  return (
    <Navigation>
      <Visibility permissionsWithRegex="(hoy.news.list|clients.hoy_web)">
        <div className="theme--news">
          <MenuNavigationItem
            to="/news"
            label={i18n._('news.title')}
            icon={Home}
          />
          <MenuDivider className="md:hidden border-gray-50" />
        </div>
      </Visibility>
      {customerProductName && (
      <Visibility permissionsWithRegex="(hoy.forms.list|clients.hoy_web)">
        <div className="theme--forms hidden md:block">
          <MenuNavigationItem
            href={`${getHoyEndpoint(customerProductName)}/tasks?rsi=${rsi}&teacher=true`}
            label={i18n._('forms.title')}
            icon={ClipboardCheck}
          />
          <MenuDivider className="md:hidden border-gray-50" />
        </div>
      </Visibility>
      )}
      <Visibility permissionsWithRegex="(hoy.messages.list|clients.hoy_web)">
        <div className="theme--messages">
          <MenuNavigationItem
            to="/messages"
            label={i18n._('messages.title')}
            icon={Mailbox}
          />
          <MenuDivider className="md:hidden border-gray-50" />
        </div>
      </Visibility>
    </Navigation>
  )
}
